import React, {Fragment, useEffect, useState} from "react";
import DatePicker from "react-flatpickr";
import {useParams} from "react-router-dom";
import {Button, Grid} from "semantic-ui-react";
import DartMatchList from "../NewDartBoard/components/MatchList/DartMatchList";
import NewDartBoard from "../NewDartBoard/NewDartBoard";
import moment from "moment";
import OverlayLoader from "../../components/OverlayLoader/OverlayLoader";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {
    changeGameLiveScore,
    getLiveScoreInfo, getLiveScoreInfoByTour, setIsADC,
    updateSidebar
} from "../../store/pages/liveScoreInfo/liveScoreInfoSlice";
import {ILiveScoreEvents, ILiveScoreInitPayload} from "../../store/pages/liveScoreInfo/types";
import {IUrlParams} from "../../constants/globalTypes";
import {GameTypes} from "../../enums/gameEvents";
import "./styles.scss";

const LiveScorePage: React.FC = () => {
    const dispatch = useAppDispatch();
    const allInfo = useAppSelector((state) => state.liveScoreInfo.allInformation);
    const isSocketConnected = useAppSelector((state) => state.connection);
    const {activeSideBarEvent, liveScoreLoader} = useAppSelector((state) => state.liveScoreInfo);
    const sidebarEvents: ILiveScoreEvents[] = allInfo.sidebarEvents;
    const {sidebar} = allInfo
    const {gameType, eventId} = useParams<IUrlParams>();
    const [selectedDate, setSelectedDate] = useState<string | Date>("");
    const [isOnlyLiveEvents, setOnlyLiveEvents] = useState(false);

    useEffect(() => {
        if (!!isSocketConnected.connection && +eventId) {
            getAllInfoForLiveScore(+eventId);
        }
        if (!eventId) {
            dispatch(getLiveScoreInfoByTour({tourId:gameType}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSocketConnected]);

    useEffect(() => {
        if (sidebar) {
            if ("date" in sidebar) {
                setSelectedDate(sidebar.date);
            }
        }
    }, [sidebar]);

    useEffect(() => {
        if (selectedDate) {
            const date: string = moment(selectedDate).format("YYYY.MM.DD");
            dispatch(updateSidebar(date));
        }
    }, [selectedDate,dispatch]);

    const onSelectMatch = (eventId: number, isAdc: boolean) => {
        const payload: ILiveScoreInitPayload = {
            gameId: eventId,
            gameType: isAdc ? GameTypes.ADC : GameTypes.CLASSIC,
            gameIdInSocket: activeSideBarEvent!,
        }
        dispatch(setIsADC(isAdc))
        dispatch(changeGameLiveScore(payload));
    };

    const getAllInfoForLiveScore = (gameId: number) => {
        const payload: ILiveScoreInitPayload = {
            gameId,
            gameIdInSocket: activeSideBarEvent!,
            gameType
        }
        dispatch(getLiveScoreInfo(payload));
    };


    const onSelectDate = (date: Date) => {
        setSelectedDate(date);
    };

    const addDayToDate = (days: number): Date => {
        const newdate = new Date(selectedDate);
        newdate.setDate(newdate.getDate() + days);
        return newdate;
    };

    const nextDay = () => {
        setSelectedDate(addDayToDate(1));
    };

    const previousDay = () => {
        setSelectedDate(addDayToDate(-1));
    };

    const filterLiveEvents = () => {
        setOnlyLiveEvents(!isOnlyLiveEvents);
    };

    const dayPickerOptions = {
        dateFormat: "Y-m-d",
        locale: {
            firstDayOfWeek: 1,
        },
    };

    return (
        <Fragment>
            <div className="live-page">
                <Grid>
                    <Grid.Column width={3}>
                        <Grid>
                            <Grid.Row className="p-0" verticalAlign="middle">
                                <Grid.Column width={2} className="p-0">
                                    <Button
                                        icon="feed"
                                        className={
                                            isOnlyLiveEvents ? "calendar-btn-enabled" : "calendar-btn"
                                        }
                                        onClick={filterLiveEvents}
                                    />
                                </Grid.Column>
                                <Grid.Column width={2} className="p-0">
                                    <Button
                                        icon="angle left"
                                        className="calendar-btn-enabled"
                                        onClick={previousDay}
                                    />
                                </Grid.Column>
                                <Grid.Column  width={10}>
                                    <div className={"datePickerInp"}>
                                        <DatePicker
                                            className="picker"
                                            value={selectedDate}
                                            options={dayPickerOptions}
                                            placeholder="Select date"
                                            onChange={(date: any) => onSelectDate(date[0])}
                                        />
                                    </div>
                                </Grid.Column>
                                <Grid.Column width="2" className="p-0">
                                    <Button
                                        icon="angle right"
                                        className="calendar-btn-enabled"
                                        onClick={nextDay}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className="darts-match-list-row">

                                {!!sidebarEvents?.length &&
                                    <DartMatchList
                                        matches={sidebarEvents}
                                        onSelect={onSelectMatch}
                                    />
                                }
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>

                    <Grid.Column className={"ls-dartboard"} width={13} style={{position: "relative"}}>
                        {liveScoreLoader ? (
                            <OverlayLoader/>
                        ) : (
                            <NewDartBoard/>
                        )}
                    </Grid.Column>
                </Grid>
            </div>
        </Fragment>
    );
};

export default LiveScorePage;
LiveScorePage.displayName = "LiveScorePage";
