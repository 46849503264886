import {
    API_CLIENT_PERMISSIONS_READ, API_CURRENT_USER_PERMISSIONS_READ,
    API_PERMISSIONS_DICTIONARY, API_USER_PERMISSIONS_READ,
} from "../../../constants";
import {errorLogger} from "../../../services/error-logger";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {client} from "../../../services/api.service";
import {toastr} from "react-redux-toastr";
import {setUserPermissions} from "../../login/authSlice";

export interface IUserPermissionBody {
    id: number | undefined,
    codes: string[]
}

export interface IPermissionsDictionary {
    id: string
    name: string
}

interface IUserGroupState {
    permissions: IPermissionsDictionary[]
    tourTypes: IPermissionsDictionary[]
}


const initialState: IUserGroupState = {
    permissions: [],
    tourTypes: []
}


export const getPermissionsDictionary = createAsyncThunk<IPermissionsDictionary[],string>(
    'permission/get-permission',
    async (type, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_PERMISSIONS_DICTIONARY}?type=${type}`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch roles.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get roles: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog)
            return rejectWithValue(error.response.data)
        }

    }
)

export const getTourTypes = createAsyncThunk<IPermissionsDictionary[]>(
    'permission/get-tour-types',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_PERMISSIONS_DICTIONARY}?type=competition"`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch roles.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get roles: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog)
            return rejectWithValue(error.response.data)
        }

    }
)

export const readUserPermissions = createAsyncThunk<IUserPermissionBody,number>(
    'permission/read-user-permission',
    async (userId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_USER_PERMISSIONS_READ}/${userId}`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch roles.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get roles: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog)
            return rejectWithValue(error.response.data)
        }

    }
)
export const readClientPermissions = createAsyncThunk<IUserPermissionBody,number>(
    'permission/read-user-permission',
    async (clientId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_CLIENT_PERMISSIONS_READ}/${clientId}`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch roles.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get roles: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog)
            return rejectWithValue(error.response.data)
        }

    }
)

export const getPermissionsForCurrentUser = createAsyncThunk<string[],string[]>(
    'permission/get-perms-for-current-user',
    async (permissions, {rejectWithValue,dispatch}) => {
        try {
            const {data} = await client.post(`${API_CURRENT_USER_PERMISSIONS_READ}`,permissions);
            dispatch(setUserPermissions(data))
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch roles.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get roles: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog)
            return rejectWithValue(error.response.data)
        }

    }
)




export const permissionsSlice = createSlice({
    name: 'userGroups',
    reducers: {},
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getPermissionsDictionary.fulfilled, (state: IUserGroupState, action) => {
                state.permissions = action.payload
            })
            .addCase(getTourTypes.fulfilled, (state: IUserGroupState, action) => {
                state.tourTypes = action.payload
            })
    },
});

export default permissionsSlice.reducer;
