import React, {useEffect, useState} from "react";
import {Button, Form, Icon, Input, Label, Message} from "semantic-ui-react";
import moment from "moment";
import DayPicker from "react-day-picker";
import Helmet from "react-helmet";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import PhaseFormTable from "../../../../../components/PhaseTable/PhaseFormTable";
import shortid from "shortid";
import ValidationModal from "../../../../../components/DartsValidationModal/ValidationModal";
import * as yup from "yup";
import "./style.scss";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {
    IDartsPhase,
    IDartsPhaseCreatePayload,
    IDartsPhaseEditPayload,
    IDartsPhaseTableData,
    IWeek
} from "../../../../../store/cms/darts/phases/types";
import {createDartsPhase, editDartsPhase, hidePhaseForm} from "../../../../../store/cms/darts/phases/cmsPhasesSlice";
import {checkIsExistWeek} from "./helpers/checkIsExistsWeek";

interface IDartsPhaseFormProps {
    dartsPhase: IDartsPhase[];
    getDateRange: (week: IWeek) => {}
}

const DartsPhaseForm: React.FC<IDartsPhaseFormProps> = ({dartsPhase, getDateRange}) => {
    const dispatch = useAppDispatch();
    const [selectedDate, setSelectedDate] = useState({
        hoverRange: {},
        selectedDays: [],
        weekNumber: 0,
    });

    const [tableData, setTableData] = useState<IDartsPhaseTableData[]>([] as IDartsPhaseTableData[]);
    const [isShowDatePicker, setIsShowDatePicker] = useState(false);
    const [validationModal, setValidationModal] = useState(false);
    const {editMode, editPhaseId} = useAppSelector(
        (state) => state.cmsPhases
    );

    let phasesValidationSchema = yup.object().shape({
        phaseName: yup.string().trim().required(),
        isValid: yup
            .string()
    });

    const {handleSubmit, control, errors, getValues, setValue} = useForm({
        mode: "onSubmit",
        resolver: yupResolver(phasesValidationSchema),
    });

    useEffect(() => {
        setDefaultFormValues()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dartsPhase, editMode, editPhaseId, getDateRange]);

    useEffect(() => {
        addSelectedDateToTable()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate.selectedDays]);

    const addSelectedDateToTable = () => {
        if (selectedDate.selectedDays.length > 0) {
            setTableData([
                ...tableData,
                {
                    weekId: shortid(),
                    date: `${moment(selectedDate.selectedDays[0])
                        .lang("en")
                        .format("LL")} - ${moment(selectedDate.selectedDays[6])
                        .lang("en")
                        .format("LL")},`,
                    weekNumber: selectedDate.weekNumber,
                    year: moment(selectedDate.selectedDays[0]).year(),
                    from: moment(selectedDate.selectedDays[0]).format(),
                    to: moment(selectedDate.selectedDays[6]).format(),
                    isFinal: false
                },
            ]);
        }
    }

    const setDefaultFormValues = () => {
        if (editMode && editPhaseId) {
            const updatablePhase = dartsPhase?.find(
                (phase) => phase.id === editPhaseId
            );
            //@ts-ignore
            const newTableData: IDartsPhaseTableData[] = updatablePhase?.weeks.map((week) => ({
                weekId: week.id,
                date: getDateRange(week),
                weekNumber: week.number,
                year: week.year,
                from: week.from,
                to: week.to,
                isFinal: week.isFinal
            }));
            setTableData(newTableData);
            setValue("phaseName", updatablePhase?.name);
        }
    }

    const submitDartsPhase = () => {
        const weeksArray = tableData?.map((item) => ({
            year: item.year,
            number: item.weekNumber,
            from: item.from,
            to: item.to,
            isFinal: item.isFinal,
            sequential: 0
        }));
        const bodyRequest: IDartsPhaseCreatePayload = {
            name: getValues("phaseName"),
            weeks: weeksArray,
        };

        dispatch(createDartsPhase(bodyRequest));
    };

    const checkTypePhaseId = (id: string | number) => (typeof id === "string" ? 0 : id);

    const updateDartsPhase = () => {
        const weeksArray = tableData?.map((item) => ({
            id: checkTypePhaseId(item.weekId),
            year: item.year,
            number: item.weekNumber,
            from: item.from,
            to: item.to,
            isFinal: item.isFinal,
            sequential: 0
        }));

        const bodyRequest: IDartsPhaseEditPayload = {
            id: editPhaseId!,
            name: getValues("phaseName"),
            weeks: weeksArray,
        };
        dispatch(editDartsPhase(bodyRequest));
    };

    const sortByTournamentDate = (a: IDartsPhaseTableData, b: IDartsPhaseTableData) => {
        const dateA = new Date(a.from);
        const dateB = new Date(b.from);

        return dateA.getTime() - dateB.getTime();
    };


    const sortTableTournaments = (tableData: IDartsPhaseTableData[]) => {
        return tableData.slice().sort(sortByTournamentDate);
    }
    const onCloseModal = () => {
        setValidationModal(false);
    };


    const checkIsExistWeekInTable = (weekNumber: number, year: number) => {
        return tableData.find(
            (item) => item.weekNumber === weekNumber && item.year === year
        )

    };

    const deleteTableWeek = (id: number) => {
        const newTableData = tableData.filter((item) => item.weekId !== id);
        setTableData(newTableData);
    };

    function getWeekRange(date: string | Date) {
        return {
            // @ts-ignore
            from: moment(date).startOf("iweek").toDate(),
            // @ts-ignore
            to: moment(date).endOf("iweek").toDate(),
        };
    }

    function getWeekDays(weekStart: Date) {
        const days = [weekStart];
        for (let i = 1; i < 7; i += 1) {
            days.push(moment(weekStart).add(i, "days").toDate());
        }
        return days;
    }


    const handleDayChange = (date: Date | string) => {
        const currentYear = moment(date).format("YYYY")
        const weekNumber = moment(date).week()
        if (checkIsExistWeek({
                dartsPhase,
                weekNumber,
                year: Number(currentYear)
            }
        )) {

            setValidationModal(true);
            return;
        }
        if (checkIsExistWeekInTable(weekNumber, Number(currentYear))) {
            setValidationModal(true);
            return;
        }
        setSelectedDate({
            ...selectedDate,
            //@ts-ignore
            selectedDays: getWeekDays(getWeekRange(date).from),
            weekNumber: moment(date).week(),
        });
    };

    const handleWeekClick = (weekNumber: number, days: any) => {
        const currentYear = moment(days[1]).format("YYYY")
        if (checkIsExistWeek({
            dartsPhase,
            weekNumber,
            year: Number(currentYear)
        })) {
            setValidationModal(true);
            return;
        }
        if (checkIsExistWeekInTable(weekNumber, Number(currentYear))) {
            setValidationModal(true);
            return;
        }
        setSelectedDate({
            ...selectedDate,
            selectedDays: days,
            weekNumber,
        });
    };

    const handleDayEnter = (date: Date) => {
        setSelectedDate({
            ...selectedDate,
            hoverRange: getWeekRange(date)!,
        });
    };

    const {hoverRange, selectedDays} = selectedDate;

    const daysAreSelected = selectedDays.length > 0;

    const modifiers = {
        hoverRange,
        selectedRange: daysAreSelected && {
            from: selectedDays[0],
            to: selectedDays[6],
        },
        //@ts-ignore
        hoverRangeStart: hoverRange && hoverRange.from,
        //@ts-ignore
        hoverRangeEnd: hoverRange && hoverRange.to,
        selectedRangeStart: daysAreSelected && selectedDays[0],
        selectedRangeEnd: daysAreSelected && selectedDays[6],
    };

    const setFinalWeek = (weekNumber: number) => {
        const tableItems = tableData.map(item => {
            if (item.weekNumber === weekNumber) {
                return {...item, isFinal: true};
            } else {
                return {...item, isFinal: false};
            }
        });
        setTableData(tableItems)
    }

    return (
        <div className="SelectedWeekExample form-overlay">
            <ValidationModal
                text={" This week already exist "}
                open={validationModal}
                onClose={onCloseModal}
            />
            <Form
                onSubmit={
                    editMode
                        ? handleSubmit(updateDartsPhase)
                        : handleSubmit(submitDartsPhase)
                }
                className="phase-form">
                <Icon
                    color="red"
                    name="close"
                    size="large"
                    onClick={() => dispatch(hidePhaseForm())}
                    style={{float: "right", cursor: "pointer"}}
                />
                <Form.Field>
                    <Label style={{marginBottom: 10}}>Phase name / number</Label>
                    <Controller
                        as={Input}
                        error={errors.hometown}
                        name="phaseName"
                        id="phaseName"
                        defaultValue=""
                        control={control}
                        placeholder="enter phase name"
                    />
                    {errors.phaseName && (
                        <Message negative>{'Phase name is required field'}</Message>
                    )}
                </Form.Field>
                <Form.Field>
                    <Label style={{marginBottom: 20}}>Weeks:</Label>
                    <PhaseFormTable
                        setFinalWeek={setFinalWeek}
                        onDeleteItem={deleteTableWeek}
                        tableData={sortTableTournaments(tableData)}
                    />
                    {isShowDatePicker ? (
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <DayPicker
                                selectedDays={selectedDate.selectedDays}
                                showWeekNumbers
                                firstDayOfWeek={1}
                                showOutsideDays
                                //@ts-ignore
                                modifiers={modifiers}
                                onDayClick={handleDayChange}
                                onDayMouseEnter={handleDayEnter}
                                onWeekClick={handleWeekClick}
                            />
                        </div>
                    ) : (
                        <div className="date-picker-btn">
                            Add week
                            <Icon
                                onClick={() => setIsShowDatePicker(true)}
                                id="addWeek"
                                color="green"
                                size="large"
                                name="plus circle"
                            />
                        </div>
                    )}
                </Form.Field>
                <div className="phase-form-btn">
                    {tableData.length > 0 && (
                        <Button color="green" id="save_competition" type="submit">
                            Submit
                        </Button>
                    )}
                </div>
            </Form>
            <Helmet>
                <style>{`
            .SelectedWeekExample .DayPicker-Month {
              border-collapse: separate;
            }
            .SelectedWeekExample .DayPicker-WeekNumber {
              outline: none;
            }
            .SelectedWeekExample .DayPicker-Day {
              outline: none;
              border: 1px solid transparent;
            }
            .SelectedWeekExample .DayPicker-Day--hoverRange {
              background-color: #EFEFEF !important;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRange {
              background-color: #fff7ba !important;
              border-top-color: #FFEB3B;
              border-bottom-color: #FFEB3B;
              border-left-color: #fff7ba;
              border-right-color: #fff7ba;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRangeStart {
              background-color: #FFEB3B !important;
              border-left: 1px solid #FFEB3B;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRangeEnd {
              background-color: #FFEB3B !important;
              border-right: 1px solid #FFEB3B;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected,
            .SelectedWeekExample .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
              border-radius: 0 !important;
              color: black !important;
            }
            .SelectedWeekExample .DayPicker-Day--hoverRange:hover {
              border-radius: 0 !important;
            }
          `}</style>
            </Helmet>
        </div>
    );
};

export default React.memo(DartsPhaseForm);
