import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {API_EXCEPTIONS} from "../../constants";
import {toastr} from "react-redux-toastr";
import {client} from "../../services/api.service";


interface IGetExceptionsResponse {
    totalItems: 0,
    pageNum: 0,
    pageSize: 0,
    message: "string",
    isOK: true,
    sort: 0,
    sortField: string,
    search: string,
    items: ILoggingItem[],
    totalPages: number

}

interface ILoggingExceptionsState {
    totalItems: number,
    pageNum: number,
    pageSize: number,
    exceptions: ILoggingItem[]
    totalPages: number
    stackTrace: IStackTrace | null
}

export interface ILoggingItem {
    id: number,
    type: string,
    message: string,
    source: string,
    date: string
}

export interface IStackTrace {
    id: 0,
    type: string,
    message: string,
    source: string,
    date: string,
    stackTrace: string
}

const initialState: ILoggingExceptionsState = {
    pageNum: 1,
    pageSize: 10,
    totalItems: 0,
    totalPages: 0,
    exceptions: [],
    stackTrace: null

}

export const getExceptions = createAsyncThunk<IGetExceptionsResponse, string>(
    'logging/get-exceptions',
    async (query, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_EXCEPTIONS}/?${query}`);
            return data
        } catch (e) {
            toastr.error('Logging get exceptions:', `${e.response.data}`);
            return rejectWithValue(e.response.data)
        }
    }
)

export const getStackTrace = createAsyncThunk<IStackTrace, string | number>(
    'logging/get-stacktrace',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_EXCEPTIONS}/${id}`);
            return data
        } catch (e) {
            toastr.error('Logging get stacktrace:', `${e.response.data}`);
            return rejectWithValue(e.response.data)
        }
    }
)

export const deleteException = createAsyncThunk<{ id: number | string }, string | number>(
    'logging/delete-exception',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_EXCEPTIONS}/${id}`);
            return data
        } catch (e) {
            toastr.error('Logging delete exception:', `${e.response.data}`);
            return rejectWithValue(e.response.data)
        }
    }
)


export const loggingExceptionsSlice = createSlice({
    name: 'loggingException',
    reducers: {
        setPageNum: (state, action) => {
            state.pageNum = action.payload
        },
        clearStackTrace: (state) => {
            state.stackTrace = null
        },
        changePageSize: (state, action) => {
            state.pageSize = action.payload
        }
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getExceptions.pending, (state: ILoggingExceptionsState) => {

            })
            .addCase(getExceptions.fulfilled, (state: ILoggingExceptionsState, action) => {
                state.exceptions = action.payload.items
                state.totalPages = action.payload.totalPages
            })
            .addCase(getStackTrace.fulfilled, (state: ILoggingExceptionsState, action) => {
                state.stackTrace = action.payload
            })
            .addCase(deleteException.fulfilled, (state: ILoggingExceptionsState, action) => {
                state.exceptions = state.exceptions.filter(exc => exc.id !== action.payload.id)
            })

    }
})
export const {setPageNum, clearStackTrace,changePageSize} = loggingExceptionsSlice.actions
export default loggingExceptionsSlice.reducer;



