import React from 'react';
import styles from "./index.module.scss"
import {Button, Icon} from "semantic-ui-react";

interface LoggingConfirmModalProps {
    onDelete: () => void
    onClose: () => void
    text: string
}

const LoggingConfirmModal: React.FC<LoggingConfirmModalProps> = ({onDelete, text, onClose}) => {
    return (
        <div className={styles.overlay}>
            <div className={styles.form}>
                <div className={styles.content}>
                    <h6 className={styles.title}>Confirmation <Icon color={"red"} name={"attention"}/></h6>
                    <div>{text}</div>
                </div>
                <div className={styles.btnWrapper}>
                    <Button className={styles.controlBtn} onClick={onClose} color={"red"}>Cancel</Button>
                    <Button className={styles.controlBtn} onClick={onDelete} color={"green"}>Delete</Button>
                </div>
            </div>
        </div>
    );
};

export default LoggingConfirmModal;
