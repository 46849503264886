import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {IUserPayload, IRole, IUser, IUserRolesState, ICMSUser} from "./types";
import {API_ROLES, API_USERS, API_USERS_INIT} from "../../../constants";
import {client} from "../../../services/api.service";
import {errorLogger} from "../../../services/error-logger";
import {ISelectOption} from "../darts/tournaments/types";

interface IGetUserResponse {
    totalItems: number,
    totalPages: number,
    pageNum: number,
    pageSize: number,
    items: ICMSUser[]
}

interface IGetRolesResponse {
    totalItems: number,
    totalPages: number,
    pageNum: number,
    pageSize: number,
    items: IRole[]
}

export interface IGetAllUserClients {
    clients: ISelectOption[]
    allowCRUD: boolean
}

const initialState: IUserRolesState = {
    roles: [] as IRole[],
    allUsers: [] as ICMSUser[],
    user: {} as ICMSUser,
    usersLoader: true,
    rolesLoader: true,
    createMode: false,
    editMode: false,
    clientId: null,
    allClients: [],
    filterValues: {
        from: '',
        search: '',
        status: 0,
        pNum: 1,
        pSize: 10,
        sort: false,
    },
    totalItems: 15,
    totalPages: 1,
    pageNum: 1,
    pageSize:10,
    allowCRUD: false
}


export const getRoles = createAsyncThunk<IGetRolesResponse>(
    'roles/get',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get(API_ROLES);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch roles.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get roles: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog)
            return rejectWithValue(error.response.data)
        }

    }
)

export const getUsers = createAsyncThunk<IGetUserResponse, string>(
    'users/get',
    async (params, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_USERS}/${params}`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch users.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get users: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog)
            return rejectWithValue(error.response.data)

        }

    }
)

export const getAllClients = createAsyncThunk<IGetAllUserClients>(
    'users/get-all-clients',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_USERS_INIT}`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch all client.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get users: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog)
            return rejectWithValue(error.response.data)

        }

    }
)
export const getSingleUser = createAsyncThunk<ICMSUser, number>(
    'users/getSingle',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_USERS}/${id}`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch a single user.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get a single user: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)

export const createUser = createAsyncThunk<ICMSUser, IUserPayload>(
    'users/post',
    async (user, {rejectWithValue}) => {
        try {
            const {data} = await client.post(API_USERS, user);
            toastr.success("CMS", "User has been created");
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to create a user.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Create user: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }

    }
)

export const editUser = createAsyncThunk<ICMSUser, IUserPayload>(
    'users/edit',
    async (user, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`${API_USERS}`, user);
            toastr.success("CMS", "User has been edited");
            return data;
        } catch (error) {
            console.log(error.message);
            toastr.error('CMS', 'Failed to edit a user.')
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Edit user: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }

    }
)

export const deleteUser = createAsyncThunk<IUser, number>(
    'users/delete',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`${API_USERS}/${id}`);
            toastr.success("CMS", "User has been deleted");
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to delete a user.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Delete user: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog)
            return rejectWithValue(error.response.message)
        }

    }
)

export const cmsUsersNRolesSlice = createSlice({
    name: 'cmsRoles',
    reducers: {
        createModeHandler: (state, action: PayloadAction<boolean>) => {
            state.createMode = action.payload;
        },
        editModeHandler: (state, action: PayloadAction<boolean>) => {
            state.editMode = action.payload;
        },
        clearSingleUserState: (state) => {
            state.user = initialState.user;
        },
        setActivePage: (state, action) => {
            state.pageNum = action.payload
        },
        changePageSize: (state, action) => {
            state.pageSize = action.payload
        },
        setClientId: (state, action) => {
            state.clientId = action.payload
        }
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getRoles.fulfilled, (state: IUserRolesState, action) => {
                state.rolesLoader = false;
                state.roles = action.payload.items;
            })
            .addCase(getRoles.rejected, (state: IUserRolesState) => {
                state.rolesLoader = false;
            })
            .addCase(getUsers.fulfilled, (state: IUserRolesState, action) => {
                state.usersLoader = false;
                state.allUsers = action.payload.items;
                state.totalPages = action.payload.totalPages;
                if (action.payload.pageNum === 0) {
                    state.pageNum = 1
                } else {
                    state.pageNum = action.payload.pageNum
                }
            })
            .addCase(getUsers.rejected, (state: IUserRolesState) => {
                state.usersLoader = false;
            })
            .addCase(getAllClients.fulfilled, (state, action) => {
                state.allClients = action.payload.clients
                state.allowCRUD = action.payload.allowCRUD

            })
            .addCase(getSingleUser.fulfilled, (state: IUserRolesState, action) => {
                state.editMode = true;
                state.user = action.payload;
            })
            .addCase(createUser.pending, (state: IUserRolesState) => {
                state.createMode = false;
                state.usersLoader = true;
            })
            .addCase(createUser.fulfilled, (state: IUserRolesState, action) => {
                state.allUsers = state.allUsers.concat(action.payload);
                state.usersLoader = false;
                state.pageNum = 1
            })
            .addCase(createUser.rejected, (state: IUserRolesState) => {
                state.usersLoader = false
            })
            .addCase(editUser.pending, (state: IUserRolesState) => {
                state.editMode = false;
                state.usersLoader = true;
            })
            .addCase(editUser.fulfilled, (state: IUserRolesState, action) => {
                state.usersLoader = false;
                state.user = {} as ICMSUser;
                state.allUsers = state.allUsers.map((user) => {
                    if (user.id === action.payload.id) {
                        user = action.payload
                    }
                    return user
                })

            })
            .addCase(editUser.rejected, (state: IUserRolesState) => {
                state.usersLoader = false;
            })
            .addCase(deleteUser.pending, (state: IUserRolesState) => {
                state.usersLoader = true;
            })
            .addCase(deleteUser.fulfilled, (state: IUserRolesState, action) => {
                state.allUsers = state.allUsers.filter((user) => {
                    return user.id !== action.meta.arg
                })
                state.usersLoader = false;
            })
            .addCase(deleteUser.rejected, (state: IUserRolesState) => {
                state.usersLoader = false;
            })
    },
});
export const {
    createModeHandler,
    editModeHandler,
    clearSingleUserState,
    setActivePage,
    setClientId,
    changePageSize
} = cmsUsersNRolesSlice.actions

export default cmsUsersNRolesSlice.reducer;
