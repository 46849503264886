import React from 'react';
import {Button, Icon} from "semantic-ui-react";
import {isShowCmsButton} from "../../helpers/isShowCmsButton";
import {handleLogout} from "../../helpers/logoutHandler";
import {useHistory} from "react-router-dom";
import {IUser} from "../../../../store/cms/usersNRoles/types";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {checkUserAccessByPermission, PagePermissionsDictionary} from "../../../../helpers/checkUserAccessByPermission";
import LoggingMenu from "../../../LoggingPages/components/LoggingMenu/LoggingMenu";
import "./index.scss"
import {isAdmin} from "../../../CMS/CmsPage/Sidebar/helpers/renderSidebarItems";

interface IScheduleTopButtonsProps {
    user: IUser
    isCrmUser: boolean | undefined
}

const ScheduleTopButtons: React.FC<IScheduleTopButtonsProps> = ({user, isCrmUser}) => {
    const history = useHistory();
    const dispatch = useAppDispatch()
    const {userPermissions} = useAppSelector((state) => state.auth);
    return (
        <div className="schedule__top-menu">
            <div className="schedule__username-block">{user?.name}</div>
            {checkUserAccessByPermission(userPermissions, PagePermissionsDictionary.StatisticDownload) &&
                <Button
                    className={'cms-btn'}
                    id="statistic"
                    secondary
                    onClick={() => history.push("/statistics")}
                >
                    <Icon style={{fontSize: "20px"}} name="chart bar"/>

                </Button>
            }
            {
                isCrmUser &&
                <Button
                    className={isShowCmsButton('cms', user) ? 'cms-btn' : 'cms-btn-hidden'}
                    id="cms"
                    secondary
                    onClick={() => history.push("/cms")}
                >
                    <Icon style={{fontSize: "16px"}} name="cogs"/>
                </Button>
            }
            {
                isAdmin(user) &&
                <Button
                    id="logging"
                    className={"loggingBtn"}
                    secondary
                >
                    <Icon style={{fontSize: "16px"}} name="info"/>
                    {<LoggingMenu/>}
                </Button>
            }
            <Button id="logout" secondary onClick={() => handleLogout(dispatch, history)}>
                Logout
            </Button>
        </div>
    );
};

export default ScheduleTopButtons;
