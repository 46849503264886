import { client, handleError } from "./api.service"
import {AxiosError} from "axios";

export const joinGameAndGetPermission = async (gameId: number) => {
  try {
    return await client.post(`/api/connection?gameId=` + gameId);
  }
  catch (err) { return handleError(err as AxiosError) }
}

export const disconnectFromGameSocket = async (gameId: number) => {
  try {
    return await client.post(`/api/connection/disconnect-from-group?gameId=` + gameId);
  }
  catch (err) { return handleError(err as AxiosError) }
}

export const joinDartsOnSpecifiedDay = async (date: string) => {
  try {
    return await client.post(`api/connection/darts-on-specified-day?date=` + date);
  }
  catch (err) { return handleError(err as AxiosError) }
}
