import React, { useEffect, useState } from 'react';
import logo from '../../img/igm_logo.svg'
import './styles.scss';
import {Link, useHistory} from "react-router-dom";
import {getVersion, getAuth} from "../../store/login/authSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";

const Login = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [code, setCode] = useState<string>('');
    const dispatch = useAppDispatch();
    const history = useHistory()

    const {errorMessage, apiVersion,isShowVerifyInput} = useAppSelector((state) => state.auth);

    useEffect(() => {
        dispatch(getVersion())
    }, [dispatch]);

    const emailChange = (event: any) => {
        setEmail(event.target.value)
    };

    const passChange = (event: any) => {
        setPassword(event.target.value)
    };

    const onEnter = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        if (event.key !== 'Enter' || !(email && password)) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        dispatch(getAuth({ email, password, history,code }));
    };

    return (
        <div className="login-container" onKeyDown={onEnter}>
            <div className="login-form">
                <div className="left">
                    <div className="inputs">
                        <input type="email" onChange={emailChange} placeholder='Username' className="input login" />
                        <input type="password" onChange={passChange} placeholder='Password' className="input login" />
                        {isShowVerifyInput && <input
                            type="password"
                            onChange={(e) => setCode(e.target.value)}
                            placeholder='Code'
                            className="input login"
                        />}
                    </div>
                    <button
                        type='submit'
                        onClick={() => dispatch(getAuth({ email, password, history,code }))}
                        className="login-btn">
                        Sign in
                    </button>
                    <div className={"password-link"}>
                        <Link to={"/password-issues"}>Password Issues?</Link>
                    </div>
                </div>
                <div className="right">
                    <div className="logo">
                        <img className="logo-img" src={logo} alt="logo"/>
                    </div>
                    <span className="logo-label">Data Collection</span>
                    {
                        errorMessage ? <span className="error">{errorMessage}</span> : <></>
                    }
                    <div className="version">{apiVersion}</div>
                </div>
            </div>
        </div>
    )
}



export default Login;
Login.displayName = "Login";
