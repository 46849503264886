import React from 'react';
import styles from "../../Messages/index.module.scss";
import Flatpickr from "react-flatpickr";
import {Icon, Input} from "semantic-ui-react";

interface LoggingFilterProps {
    startDate: Date | string | null
    endDate: Date | string | null
    setStartDate: (date: string) => void
    setEndDate: (date: string) => void
    search: string
    setSearch: (searchWord: string) => void
    showRefresh?: boolean
}

const LoggingFilter: React.FC<LoggingFilterProps> = (
    {
        startDate,
        endDate,
        setEndDate,
        setStartDate,
        search,
        setSearch,
        showRefresh = false

    }) => {

    const dayPickerOptions = {
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
        locale: {
            firstDayOfWeek: 1,
        },
    };

    return (
        <div>
            <div className={styles.filters}>
                <div className={styles.inputInner}>
                    <Input
                        icon='search'
                        placeholder='Search...'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <div className={styles.filterText}>from</div>
                <div className={styles.inputInner}>
                    <Flatpickr
                        placeholder={"Select date"}
                        className="statistics__flatpickr"
                        value={startDate}
                        options={dayPickerOptions}
                        onChange={(date: Date, dayStr: string) => setStartDate(dayStr)}
                    />
                </div>
                <div className={styles.filterText}>to</div>
                <div className={styles.inputInner}>
                    <Flatpickr
                        placeholder={"Select date"}
                        className="statistics__flatpickr"
                        value={endDate}
                        options={dayPickerOptions}
                        onChange={(date: Date, dayStr: string) => setEndDate(dayStr)}
                    />
                </div>
                {showRefresh &&
                    <button
                        onClick={() => window.location.reload()}
                        className={styles.refresh}>
                        <Icon color={"green"} name={"refresh"}/>
                    </button>
                }
            </div>
        </div>
    );
};

export default LoggingFilter;
