import React, {useState} from 'react';
import plutoTVLogo from "../../../../../img/PlutoTV_Logo_Wordmark_Yellow_TranBG.svg"
import {BestCompetitionType} from "../GameScores";
import {animated, useSpring} from "@react-spring/web";
import instFooter from "../../../../../img/inst-footer.png";
import twFooter from "../../../../../img/twitterX.png";
import fbFooter from "../../../../../img/fb-footer.png";
import "./styles.scss"


interface GameScoresFooter {
    colorType: BestCompetitionType
}

const GameScoresFooter: React.FC<GameScoresFooter> = ({colorType}) => {
    const [isSocialBlock, setIsSocialBlock] = useState(true);

    const animation = useSpring({
        from: { opacity: 0, transform: 'translateX(100%)' },
        to: async (next) => {
            await next({ opacity: 1, transform: 'translateX(0%)' });
            await new Promise((resolve) => setTimeout(resolve, 8000));
            await next({ opacity: 0, transform: 'translateX(-100%)' });
        },
        reset: colorType === BestCompetitionType.STANDARD,
        onRest: () => setIsSocialBlock((prev) => !prev),
        config: { duration: 500 },
    });


    const renderFooterContent = () => {
        if (colorType === BestCompetitionType.STANDARD) {
            return (
                <div className="social-footer-content">
                    {isSocialBlock ? (
                        <animated.div className="socialBlock" style={animation}>
                            <div className="social-icon">
                                <img src={instFooter} alt="Instagram"/>
                            </div>
                            <div className="social-icon">
                                <img src={twFooter} alt="Twitter"/>
                            </div>
                            <div className="social-icon fb-icon">
                                <img src={fbFooter} alt="Facebook"/>
                            </div>
                            <div className={"mssDarts"}>@MSSdarts</div>
                            {/*<div className={'social-footer-content'}>*/}
                            {/*    <div>ADC Global Championship</div>*/}
                            {/*</div>*/}
                        </animated.div>
                    ) : (
                        <animated.div className="availableBlock" style={animation}>
                            <div className={"plutoTVLogoInner"}>Available on
                                <div className={"plutoTVLogo"}><img src={plutoTVLogo} alt="pluto"/></div>
                            </div>

                        </animated.div>
                    )}
                </div>
            )
        }
        if (colorType === BestCompetitionType.WADC) {
            return (
                <div className={'social-footer-content'}>
                    <div>WADC WINMAU Championship Tour Finals</div>
                </div>
            )
        }

        if (colorType === BestCompetitionType.ADC) {
            return (
                <div className={'social-footer-content'}>
                    <div>ADC Global Championship</div>
                </div>
            )
        }
    }

    return (
        <div className={'game-scores-footer'}>
            <div>
                <div className={"social-footer"}>
                    {renderFooterContent()}
                </div>
            </div>
        </div>
    );
};

export default React.memo(GameScoresFooter);
