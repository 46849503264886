import {DartsGame} from "../../../store/pages/schedule/types";
import {GameTypes} from "../../../enums/gameEvents";

export const setGameType = (games: DartsGame[], selectedGameId: any, setType: (type:GameTypes) => void) => {
    const fullGameInfo = games.find(game => game.id === selectedGameId)
    if(fullGameInfo) {
        if (fullGameInfo.is9shots) {
            setType(GameTypes.SHOOTOUT)
        }
        else {
            setType(GameTypes.CLASSIC)
        }
    }

}