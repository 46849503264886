import React, {useEffect, useState} from 'react';
import {Button, Input} from 'semantic-ui-react';
import MssNewsForm from "./MssNewsForm/MssNewsForm";
import DimmerLoader from "../../../components/DImmerLoader/DimmerLoader";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {
    formHandler,
    getNews, setPageNum, setPageSize,
} from "../../../store/cms/modusNews/cmsModusNewsSlice";
import {renderNewsTable} from "./helpers/renderNewsTable";
import {buildQueryString} from "../Darts/DartsGames/helpers/buildQueryString";
import AppPagination from "../../../components/Pagination/AppPagination";
import './style.scss';

const MssNewsTable = () => {
    const dispatch = useAppDispatch();
    const {
        news,
        isOpen,
        isLoading,
        editMode,
        pageSize,
        pageNum,
        totalPages,
    } = useAppSelector((state) => state.cmsNews);
    const {isRenderControls} = useAppSelector((state) => state.auth);
    const [searchWord, setSearchWord] = useState("");

    useEffect(() => {
        const params = {pageSize, pageNum}
        dispatch(getNews(buildQueryString(params)))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const formVisibilityHandler = () => dispatch(formHandler(!isOpen));

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const params = {search: e.target.value, pageNum: 1, pageSize}
        setSearchWord(e.target.value)
        dispatch(getNews(buildQueryString(params)))
    }

    const onChangePager = (pageNum: any) => {
        dispatch(setPageNum(pageNum))
        const params = {search: searchWord, pageNum, pageSize}
        dispatch(getNews(buildQueryString(params)))
    }

    const onChangePageSize = (value: any) => {
        dispatch(setPageSize(value))
        const params = {search: searchWord, pageNum, pageSize: value}
        dispatch(getNews(buildQueryString(params)))
    }

    return (
        <div>
            <div className="mss-top-controls">
                <div>
                    <Input
                        icon='search'
                        placeholder='Search...'
                        value={searchWord}
                        onChange={(e) => onSearch(e)}
                    />
                </div>
                <div>
                    {isRenderControls && <Button
                        disabled={isOpen || isLoading || editMode}
                        onClick={formVisibilityHandler}
                        content="Add"
                        color="green"
                    />}
                </div>
            </div>
            {isLoading && news ? <DimmerLoader/> : renderNewsTable({
                news,
                isLoading,
                editMode,
                isOpen,
                dispatch,
                isRenderControls
            })}

            {isOpen && <MssNewsForm/>}
            <AppPagination
                activePage={pageNum}
                pageSize={pageSize}
                totalPages={totalPages}
                onChangePager={onChangePager}
                onChangePageSize={onChangePageSize}
            />
        </div>
    );
}

export default MssNewsTable;
