import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {client} from "../../../services/api.service";
import {errorLogger} from "../../../services/error-logger";
import {IClientState, IClient} from "./types";
import {API_CLIENTS} from "../../../constants";

export interface IGetClientResponse {
    totalItems: number,
    totalPages: number,
    pageNum: number
    items:IClient[]
}

const initialState: IClientState = {
    allClients: [] as IClient[],
    client: {} as IClient,
    loader: true,
    createMode: false,
    editMode: false,
    totalItems: 0,
    totalPages: 0,
    pageNum: 1
}

export const getClients = createAsyncThunk<IGetClientResponse,string>(
    'clients/get',
    async (query, {rejectWithValue}) => {
        try {
            const { data } = await client.get(`${API_CLIENTS}${query ? `?${query}` : ""}`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch clients.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get clients: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.message.data);
        }
    }
)

export const getSingleClient = createAsyncThunk<IClient, number>(
    'clients/getSingle',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_CLIENTS}/${id}`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch a single client.')

            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get a single client: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog)
            return rejectWithValue(error.response.message);
        }
    }
);

export const createClient = createAsyncThunk<IClient, IClient>(
    'clients/post',
    async (user, {rejectWithValue}) => {
        try {
            const {data} = await client.post(API_CLIENTS, user);
            toastr.success("CMS", "Client has been created");
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to create a client.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Create client: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog)
            return rejectWithValue(error.response.data)
        }
    }
)

export const editClient = createAsyncThunk<IClient, IClient>(
    'client/edit',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`${API_CLIENTS}`, payload);
            toastr.success("CMS", "Client has been edited");
            return data;
        } catch (error) {
            console.log(error.message);
            toastr.error('CMS', 'Failed to edit a client.')
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Edit client: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog)
            return rejectWithValue(error.response.data);
        }

    }
)

export const deleteClient = createAsyncThunk<IClient, number>(
    'clients/delete',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`${API_CLIENTS}/${id}`);
            toastr.success("CMS", "Client has been deleted");
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to delete a client.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Delete client: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }

    }
)

export const cmsClientsSlice = createSlice({
    name: 'cmsClients',
    reducers: {
        createModeHandler: (state, action: PayloadAction<boolean>) => {
            state.createMode = action.payload;
        },
        editModeHandler: (state, action: PayloadAction<boolean>) => {
            state.editMode = action.payload;
        },
        clearSingleClientState: (state) => {
            state.client = initialState.client;
        },
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getClients.fulfilled, (state: IClientState, action) => {
                state.loader = false;
                state.allClients = action.payload.items;
            })
            .addCase(getClients.rejected, (state: IClientState) => {
                state.loader = false;
            })
            .addCase(getSingleClient.fulfilled, (state: IClientState, action) => {
                state.editMode = true;
                state.client = action.payload;
            })
            .addCase(createClient.pending, (state: IClientState) => {
                state.createMode = false;
                state.loader = true;
            })
            .addCase(createClient.fulfilled, (state: IClientState, action) => {
                state.allClients = state.allClients.concat(action.payload);
                state.loader = false;
            })
            .addCase(createClient.rejected, (state: IClientState) => {
                state.loader = false
            })
            .addCase(editClient.pending, (state: IClientState) => {
                state.editMode = false;
                state.loader = true;
            })
            .addCase(editClient.fulfilled, (state: IClientState, action) => {
                state.loader = false;
                state.allClients = state.allClients.map((client) => {
                    if (client.id === action.payload.id) {
                        client = action.payload
                    }
                    return client
                })

            })
            .addCase(editClient.rejected, (state: IClientState) => {
                state.loader = false;
            })
            .addCase(deleteClient.pending, (state: IClientState) => {
                state.loader = true;
            })
            .addCase(deleteClient.fulfilled, (state: IClientState, action) => {
                state.allClients = state.allClients.filter((client) => {
                    return client.id !== action.meta.arg
                })
                state.loader = false;
            })
            .addCase(deleteClient.rejected, (state: IClientState) => {
                state.loader = false;
            })
    },
});
export const {
    createModeHandler,
    editModeHandler,
    clearSingleClientState
} = cmsClientsSlice.actions

export default cmsClientsSlice.reducer;
