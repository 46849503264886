import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    DartsCurrentGameState,
    IDartsCurrentGameState,
    IDartsCurrentGameTimeline,
    IDartsGameScoreInit,
    IDartsThrow,
    IDoubleAttemptPayload,
    IGameIdDatePayload,
    IMatchStatusPayload,
    ISetFirstPlayer, IShootCurrentGameTimeline,
    IShootOutCurrentGame,
    IThrowPayload,
    IThrowShootPayloadData,
    IWalkoverPayload
} from "./types";
import {client} from "../../../services/api.service";
import {
    API_DARTS_FIRSTPLAYER,
    API_DARTS_SCORE_INIT,
    API_DARTS_THROW,
    API_DARTS_THROW_DOUBLEATTEMPT,
    API_DARTS_UNDO_THROW,
    API_DARTS_WALKOVER
} from "../../../constants";
import {errorLogger} from "../../../services/error-logger";
import {toastr} from "react-redux-toastr";
import {GameStatuses} from "../../../enums/gameStatus";
import {
    setAdcGameStatus,
    setClassicGameStatus,
    setGameRunningLoader,
    setShootOutGameStatus
} from "../handler/gameHandlerSlice";
import {API_GAME_STATUS, setMatchStatusForCertainGame} from "./helpers/setMatchStatusForCertainGame";
import {setEndGame} from "./helpers/setEndGame";
import {DartsGameTypes, GameTypes} from "../../../enums/gameEvents";
import {selectTournament} from "../../pages/schedule/scheduleSlice";

const initialState: DartsCurrentGameState = {
    currentGameInitData: {} as IDartsCurrentGameState,
    currentShootOutGameInitData: {} as IDartsCurrentGameState,
    currentPlayerId: 0,
    currentGameStatus: 0,
    isThrowAllowed: true,
    isUndoThrowAllowed: false,
    currentGameLoader: false
}

export const getInitScoreForCurrentGame = createAsyncThunk<IDartsCurrentGameState, IDartsGameScoreInit>(
    'currentGame/getInitScore',
    async (gameData, {rejectWithValue}) => {
        try {
            if (gameData.gameType === GameTypes.ADC) {
                const {data} = await client.post(`${API_DARTS_SCORE_INIT}`, {
                    id: gameData.gameId,
                    type: DartsGameTypes.ADC
                });
                return data;
            }
            const {data} = await client.post(`${API_DARTS_SCORE_INIT}`, {
                id: gameData.gameId,
                type: DartsGameTypes.CLASSIC
            });
            return data;
        } catch (error) {
            console.error(error.message);
            toastr.error('CMS', 'Failed to fetch data for current darts handler.')
            const errorLog = {
                projectName: 'DC',
                errorMessage: `getInitScoreForCurrGame: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
);

export const getInitScoreForShootOutGame = createAsyncThunk<IDartsCurrentGameState, number>(
    'currentGame/getInitShootOutScore',
    async (gameId, {rejectWithValue}) => {
        try {
            const {data} = await client.post(`${API_DARTS_SCORE_INIT}`, {id: gameId, type: DartsGameTypes.SHOOTOUT});
            return data;
        } catch (error) {
            console.error(error.message);
            toastr.error('CMS', 'Failed to fetch data for current shoot out darts handler.')
            const errorLog = {
                projectName: 'DC',
                errorMessage: `currentGame/getInitShootOutScore: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog)
            return rejectWithValue(error.response.data);
        }
    }
);

export const startDartsGame = createAsyncThunk<void | unknown, IGameIdDatePayload>(
    'currentGame/startGame',
    async (payload, {dispatch, rejectWithValue}) => {
        const {gameData, gameType} = payload;
        const body = {
            gameId: gameData.gameId,
            status: GameStatuses.Running,
            date: gameData.date,
        }
        try {
            if (gameType === GameTypes.ADC) {
                dispatch(setGameRunningLoader(true));
                await client.put(`${API_GAME_STATUS}`, {...body, type: DartsGameTypes.ADC});
                dispatch(setAdcGameStatus(GameStatuses.Running));
                localStorage.setItem('isGameRunning', JSON.stringify(true))
                return dispatch(setGameRunningLoader(false));
            }
            if (gameType === GameTypes.SHOOTOUT) {
                dispatch(setGameRunningLoader(true));
                await client.put(`${API_GAME_STATUS}`, {...body, type: DartsGameTypes.SHOOTOUT});
                dispatch(setShootOutGameStatus(GameStatuses.Running));
                localStorage.setItem('isGameRunning', JSON.stringify(true));
                return dispatch(setGameRunningLoader(false));
            }

            dispatch(setGameRunningLoader(true));
            await client.put(`${API_GAME_STATUS}`, {...body, type: DartsGameTypes.CLASSIC});
            dispatch(setClassicGameStatus(GameStatuses.Running));
            localStorage.setItem('isGameRunning', JSON.stringify(true))
            return dispatch(setGameRunningLoader(false));

        } catch (error) {
            console.error(error.message);
            toastr.error('CMS', 'Failed to start a handler.')
            const errorLog = {
                projectName: 'DC',
                errorMessage: `currentGame/startGame: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
);

export const addThrow = createAsyncThunk<IDartsThrow, IThrowPayload>(
    'currentGame/addThrow',
    async (throwPayload, {rejectWithValue}) => {
        const {gameData, gameType} = throwPayload;
        try {
            if (gameType === GameTypes.ADC) {
                const adcResp = await client.post(
                    `${API_DARTS_THROW}`,
                    {...gameData,type:DartsGameTypes.ADC},
                    {withCredentials: true, timeout: 30000}
                );
                return adcResp.data;
            }

            const {data} = await client.post(
                `${API_DARTS_THROW}`,
                {...gameData,type:DartsGameTypes.CLASSIC},
                {withCredentials: true, timeout: 30000}
            );
            return data;
        } catch (error) {
            console.error(error.message);
            toastr.error('CMS', 'Failed to throw.')
            const errorLog = {
                projectName: 'DC',
                errorMessage: `currentGame/addThrow: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
);

export const addShootOutThrow = createAsyncThunk<IShootOutCurrentGame, IThrowShootPayloadData>(
    'currentGame/addShootOutThrow',
    async (throwPayload, {rejectWithValue}) => {
        try {
            const {data} = await client.post(
                `${API_DARTS_THROW}`,
                {...throwPayload,type:DartsGameTypes.SHOOTOUT},
                {withCredentials: true, timeout: 30000}
            );
            return data;
        } catch (error) {
            console.error(error.message);
            toastr.error('CMS', 'Failed to throw.')
            const errorLog = {
                projectName: 'DC',
                errorMessage: `currentGame/addThrow: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
);

export const setFirstPlayer = createAsyncThunk<IDartsCurrentGameTimeline[] | IShootCurrentGameTimeline[], ISetFirstPlayer>(
    'currentGame/setFirstPlayer',
    async (firstPlayerPayload, {rejectWithValue}) => {
        const {gameData, gameType} = firstPlayerPayload;
        try {
            if (gameType === GameTypes.ADC) {
                const {data} = await client.post(
                    `${API_DARTS_FIRSTPLAYER}`,
                    {...gameData, type: DartsGameTypes.ADC}
                );
                return data;
            }
            if (gameType === GameTypes.SHOOTOUT) {
                const {data} = await client.post(
                    `${API_DARTS_FIRSTPLAYER}`,
                    {...gameData, type: DartsGameTypes.SHOOTOUT}
                );
                return data;
            }
            const {data} = await client.post(
                `${API_DARTS_FIRSTPLAYER}`,
                {...gameData, type: DartsGameTypes.CLASSIC}
            );
            return data;
        } catch (error) {
            console.error(error.message);
            toastr.error('CMS', 'Failed to setting a first player.')
            const errorLog = {
                projectName: 'DC',
                errorMessage: `currentGame/setFirstPlayer: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }

    }
);

export const undoThrow = createAsyncThunk<IDartsCurrentGameState | IShootOutCurrentGame, IGameIdDatePayload>(
    'currentClassicGame/undoThrow',
    async (undoPayload, {rejectWithValue}) => {
        const {gameData, gameType} = undoPayload;
        try {
            switch (gameType) {
                case GameTypes.ADC:
                    const adcResponse = await client.post(`${API_DARTS_UNDO_THROW}`, {
                        ...gameData,
                        type: DartsGameTypes.ADC
                    });
                    return adcResponse.data;

                case GameTypes.SHOOTOUT:
                    const shootOutResponse = await client.post(`${API_DARTS_UNDO_THROW}`, {
                        ...gameData,
                        type: DartsGameTypes.SHOOTOUT
                    });
                    return shootOutResponse.data;

                default:
                    const response = await client.post(`${API_DARTS_UNDO_THROW}`, {
                        ...gameData,
                        type: DartsGameTypes.CLASSIC
                    });
                    return response.data;
            }
        } catch (error) {
            console.error(error.message);
            toastr.error('CMS', 'Failed to undo.')
            const errorLog = {
                projectName: 'DC',
                errorMessage: `currentClassicGame/undoThrow: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }

    }
);

export const doubleAttempt = createAsyncThunk<null | number, IDoubleAttemptPayload>(
    'currentClassicGame/doubleAttempt',
    async (undoPayload, {rejectWithValue}) => {
        try {
            if (undoPayload.type === DartsGameTypes.ADC) {
                const adcResponse = await client.post(API_DARTS_THROW_DOUBLEATTEMPT, undoPayload);
                return adcResponse.data;
            }
            const response = await client.post(API_DARTS_THROW_DOUBLEATTEMPT, undoPayload);
            return response.data;
        } catch (error) {
            console.error(error.message);
            toastr.error('CMS', 'Failed to double attempt.')
            const errorLog = {
                projectName: 'DC',
                errorMessage: `currentClassicGame/doubleAttempt: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
);

export const setWalkover = createAsyncThunk<void, IWalkoverPayload>(
    'currentClassicGame/walkover',
    async (walkoverPayload, {dispatch, rejectWithValue}) => {
        try {
            const {gameData, gameType, history} = walkoverPayload;
            if (gameType === GameTypes.ADC) {
                const {data} = await client.put(`${API_DARTS_WALKOVER}-adc/${gameData.gameId}`, gameData);
                dispatch(setCurrentGameStatus(GameStatuses.Walkover));
                if (history) {
                    history.go(-1)
                }
                return data;
            }
            const {data} = await client.put(`${API_DARTS_WALKOVER}/${gameData.gameId}`, gameData);
            dispatch(setCurrentGameStatus(GameStatuses.Walkover));
            if (history) {
                history.go(-1)
            }
            return data;
        } catch (error) {
            console.error(error.message);
            toastr.error('CMS', 'Failed to set walkover.')
            const errorLog = {
                projectName: 'DC',
                errorMessage: `currentClassicGame/walkover: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
);

export const setDartsGameStatus = createAsyncThunk<void, IMatchStatusPayload>(
    'currentGame/setDartsGameStatus',
    async (matchStatusPayload, {dispatch, rejectWithValue}) => {
        const {gameData} = matchStatusPayload;
        try {
            if (gameData.status === 'end') {
                await setEndGame(matchStatusPayload, dispatch);
                return
            }
            dispatch(selectTournament(0));
            await setMatchStatusForCertainGame(matchStatusPayload, dispatch);
        } catch (error) {
            console.error(error.message);
            toastr.error('CMS', 'Failed to change a handler status.')
            const errorLog = {
                projectName: 'DC',
                errorMessage: `currentGame/setDartsGameStatus: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
);

export const dartsCurrentGameSlice = createSlice({
    name: 'dartsCurrentGame',
    reducers: {
        setCurrentGameStatus: (state, action: PayloadAction<number | string>) => {
            state.currentGameStatus = action.payload;
        },
        setCurrentPlayerId: (state, action: PayloadAction<number>) => {
            state.currentPlayerId = action.payload;
        },
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(startDartsGame.fulfilled, (state: DartsCurrentGameState) => {
                state.isThrowAllowed = true;
            })
            .addCase(getInitScoreForCurrentGame.pending, (state: DartsCurrentGameState) => {
                state.currentGameLoader = true;
            })
            .addCase(getInitScoreForCurrentGame.fulfilled, (state: DartsCurrentGameState, action) => {
                state.currentGameInitData = action.payload;
                state.currentPlayerId = action.payload.playerId;
                state.currentGameStatus = action.payload.status;
                state.isUndoThrowAllowed = true;
                state.currentGameLoader = false;
            })
            .addCase(getInitScoreForCurrentGame.rejected, (state: DartsCurrentGameState) => {
                state.currentGameLoader = false;
            })
            .addCase(getInitScoreForShootOutGame.pending, (state: DartsCurrentGameState) => {
                state.currentGameLoader = true;
            })
            .addCase(getInitScoreForShootOutGame.fulfilled, (state: DartsCurrentGameState, action) => {
                state.currentShootOutGameInitData = action.payload;
                state.currentPlayerId = action.payload.playerId;
                state.currentGameStatus = action.payload.status;
                state.isUndoThrowAllowed = true;
                state.currentGameLoader = false;
            })
            .addCase(getInitScoreForShootOutGame.rejected, (state: DartsCurrentGameState) => {
                state.currentGameLoader = false;
            })
            .addCase(setDartsGameStatus.pending, (state: DartsCurrentGameState) => {
                state.isThrowAllowed = false;
                state.isUndoThrowAllowed = false;
            })
            .addCase(setDartsGameStatus.rejected, (state: DartsCurrentGameState) => {
                state.isThrowAllowed = true;
                state.isUndoThrowAllowed = true;
            })
            .addCase(setWalkover.pending, (state: DartsCurrentGameState) => {
                state.isThrowAllowed = false;
                state.isUndoThrowAllowed = false;
            })
            .addCase(setFirstPlayer.pending, (state: DartsCurrentGameState) => {
                state.isThrowAllowed = false;
                state.isUndoThrowAllowed = false;
            })
            .addCase(setFirstPlayer.fulfilled, (state: DartsCurrentGameState, action) => {
                const {gameType, gameData} = action.meta.arg;
                state.currentPlayerId = gameData.playerId;
                state.currentShootOutGameInitData.playerId = gameData.playerId
                if (gameType === GameTypes.SHOOTOUT) {
                    state.currentShootOutGameInitData.timeline = action.payload as IDartsCurrentGameTimeline[];
                }
                if (gameType === GameTypes.CLASSIC) {
                    state.currentGameInitData.timeline = action.payload as IDartsCurrentGameTimeline[];
                }
                state.isThrowAllowed = true;
                state.isUndoThrowAllowed = true;
            })
            .addCase(setFirstPlayer.rejected, (state: DartsCurrentGameState) => {
                state.isThrowAllowed = true;
                state.isUndoThrowAllowed = true;
            })
            .addCase(setWalkover.rejected, (state: DartsCurrentGameState) => {
                state.isThrowAllowed = true;
                state.isUndoThrowAllowed = true;
            })
            .addCase(addShootOutThrow.pending, (state: DartsCurrentGameState) => {
                state.isThrowAllowed = false;
                state.isUndoThrowAllowed = false;
            })
            .addCase(addShootOutThrow.fulfilled, (state: DartsCurrentGameState, action) => {
                const {status, timeline, score, playerId, turnScores} = action.payload as unknown as IDartsCurrentGameState;
                state.currentShootOutGameInitData.playerId = playerId;
                state.currentPlayerId = playerId;
                state.isUndoThrowAllowed = true;
                state.isThrowAllowed = true;
                state.currentGameLoader = false;
                state.currentShootOutGameInitData.turnScores = turnScores
                if (status === GameStatuses.PreEnd) {
                    state.currentGameStatus = status;
                }
                if (score !== null) {
                    state.currentShootOutGameInitData.score = score;
                    state.currentShootOutGameInitData.timeline = [...state.currentShootOutGameInitData.timeline, ...timeline]
                } else {
                    state.currentShootOutGameInitData.timeline = [...state.currentShootOutGameInitData.timeline, ...timeline]
                }
                state.isUndoThrowAllowed = true;
                state.isThrowAllowed = true;
            })
            .addCase(addShootOutThrow.rejected, (state: DartsCurrentGameState) => {
                state.isUndoThrowAllowed = true;
                state.isThrowAllowed = true;
            })
            .addCase(addThrow.pending, (state: DartsCurrentGameState) => {
                state.isUndoThrowAllowed = false;
                state.isThrowAllowed = false;
            })
            .addCase(addThrow.fulfilled, (state: DartsCurrentGameState, action) => {
                if (action.payload) {
                    const {status, timeline, legs, score, playerId, turnScores} = action.payload as IDartsThrow;
                    state.currentPlayerId = playerId;
                    state.isUndoThrowAllowed = true;
                    state.isThrowAllowed = true;
                    state.currentGameLoader = false;

                    if (status === GameStatuses.PreEnd) {
                        state.currentGameStatus = status;
                    }
                    if (legs !== null) {
                        // @ts-ignore
                        state.currentGameInitData.legs = legs;
                    }
                    if (turnScores) {
                        state.currentGameInitData.turnScores = turnScores
                    }
                    if (score !== null) {
                        state.currentGameInitData.score = score;
                        state.currentGameInitData.timeline = [...state.currentGameInitData.timeline, ...timeline]
                    } else {
                        state.currentGameInitData.timeline = [...state.currentGameInitData.timeline, ...timeline]
                    }
                }
            })
            .addCase(addThrow.rejected, (state: DartsCurrentGameState) => {
                state.currentGameLoader = false;
            })
            .addCase(undoThrow.pending, (state: DartsCurrentGameState) => {
                state.isUndoThrowAllowed = false;
                state.isThrowAllowed = false;
            })
            .addCase(undoThrow.fulfilled, (state: DartsCurrentGameState, action) => {
                if (action.meta.arg.gameType === GameTypes.SHOOTOUT) {
                    state.currentShootOutGameInitData = action.payload as IDartsCurrentGameState;
                }
                state.currentGameInitData = action.payload as IDartsCurrentGameState;
                state.currentPlayerId = action.payload.playerId;
                state.currentGameStatus = GameStatuses.Running;
                state.isUndoThrowAllowed = true;
                state.isThrowAllowed = true;
            })
            .addCase(undoThrow.rejected, (state: DartsCurrentGameState) => {
                state.isUndoThrowAllowed = true;
                state.isThrowAllowed = true;
            })
            .addCase(doubleAttempt.pending, (state: DartsCurrentGameState) => {
                state.isThrowAllowed = false;
            })
            .addCase(doubleAttempt.fulfilled, (state: DartsCurrentGameState, action) => {
                state.currentGameInitData.timeline.forEach((item) => {
                    if (item.id === action.meta.arg.throwId) {
                        item.doubleAttempt = action.payload;
                    }
                })
                state.isThrowAllowed = true;

            })
            .addCase(doubleAttempt.rejected, (state: DartsCurrentGameState) => {
                state.isThrowAllowed = true;
            })
    }
})
export const {
    setCurrentGameStatus,
} = dartsCurrentGameSlice.actions;

export default dartsCurrentGameSlice.reducer;
