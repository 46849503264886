import React from 'react';
import {Button} from "semantic-ui-react";
import {GameTypes} from "../../../../enums/gameEvents";
import {DartsGameItem} from "../../../../store/dartsGame/handler/types";
import {useHistory} from "react-router-dom";
import {DartsADCGame, DartsGame} from "../../../../store/pages/schedule/types";
import {selectAdcGame, selectClassicGame} from "../../../../store/pages/schedule/scheduleSlice";
import {setDartsGame} from "../../../../store/dartsGame/handler/gameHandlerSlice";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {setCurrentGameId} from "../../../../store/pages/scoreboardAndStats/scoreboardAndStatsSlice";
import {checkUserAccessByPermission, PagePermissionsDictionary} from "../../../../helpers/checkUserAccessByPermission";

interface IScheduleActiveButtonsProps {
    isScoringUser: boolean | undefined
    isViewerUser: boolean | undefined
    selectedAdcGame: number
    selectedGame: number
    games: DartsGame[]
    activeAdcGame: DartsADCGame | null | undefined
    isAdcGameSelected: boolean
    activeIs9shots: boolean
    activeGameId: number,
    setIsAdcGameSelected: React.Dispatch<React.SetStateAction<boolean>>
    activeIsADC: boolean
    activeTournamentId: number
    selectedTournament: number
    selectedGameType: GameTypes | null
}

const ScheduleActiveButtons: React.FC<IScheduleActiveButtonsProps> = (
    {
        isScoringUser,
        isViewerUser,
        selectedGame,
        selectedAdcGame,
        games,
        activeAdcGame,
        isAdcGameSelected,
        activeIs9shots,
        activeGameId,
        setIsAdcGameSelected,
        activeIsADC,
        activeTournamentId,
        selectedGameType
    }) => {
    const {userPermissions} = useAppSelector((state) => state.auth);
    const history = useHistory();
    const dispatch = useAppDispatch()

    const login = () => {
        if (activeGameId && !activeIsADC) {
            if (isAdcGameSelected && activeGameId) {
                dispatch(selectAdcGame(Number(0)));
                dispatch(selectClassicGame(Number(activeGameId)));
                setIsAdcGameSelected(false)
                alert("Standard handler is running now");
                return;
            }
            if ((activeGameId !== selectedGame)) {
                alert("This game is not active");
                return;
            }
        } else if (activeIsADC) {
            if (activeGameId !== selectedAdcGame) {
                alert("This game is not active");
                return;
            }
        }

        if (selectedAdcGame) {
            return dispatch(setDartsGame({game: activeAdcGame!, history, selectedAdcGame}));
        }

        if (selectedGame || activeGameId) {
            const game = games?.find((game: DartsGameItem) => game.id === selectedGame);
            if (game) {
                return dispatch(setDartsGame({game, history}));
            }
        }
    };

    const goToMatchStats = () => {

        if (activeAdcGame || isAdcGameSelected || selectedAdcGame) {
            dispatch(setCurrentGameId(selectedAdcGame));
            return history.push(`match-stats-table/${GameTypes.ADC.toLowerCase()}/${selectedAdcGame}`);
        }
        if (selectedGame && !isAdcGameSelected && !activeIs9shots) {
            dispatch(setCurrentGameId(selectedGame));
            return history.push(`match-stats-table/${GameTypes.CLASSIC.toLowerCase()}/${selectedGame}`);
        }
        if (activeIs9shots) {
            const currentActiveGame = games.find((game: DartsGameItem) => game.id === selectedGame)
            if (currentActiveGame!.is9shots) {
                history.push(`shootout-match-stats-table/${activeGameId}/${activeTournamentId}`);
            }
        }

    };

    const goToActiveGameScore = () => {
        if (activeIs9shots) {
            return history.push(`/shootout-active-game-scores/${activeGameId}`);
        }
        if (selectedAdcGame || isAdcGameSelected) {
            return history.push(`/active-game-scores/${GameTypes.ADC.toLowerCase()}/${activeGameId}`);
        }
        return history.push(`/active-game-scores/${GameTypes.CLASSIC.toLowerCase()}/${activeGameId}`);
    };

    const gotToPreMatchStats = () => {
        history.push(`/pre-match-stats/${selectedGame}`);
    };

    const gotToPostMatch = () => {
        history.push(`post-match-page/${selectedGame}`);
    };
    return (
        <div className="schedule__login">
            {
                isScoringUser &&
                <Button
                    id="login"
                    color="red"
                    onClick={login}>
                    Login
                </Button>
            }

            {
                isViewerUser &&
                <>
                    {checkUserAccessByPermission(userPermissions, PagePermissionsDictionary.ScoreboardAndStats) &&
                        <Button
                            id="scoreboard"
                            color="yellow"
                            onClick={goToMatchStats}
                        >
                            Scoreboard & Stats
                        </Button>
                    }
                    {checkUserAccessByPermission(userPermissions, PagePermissionsDictionary.ActiveGameScore) &&
                        <Button
                            id="active_score"
                            color="green"
                            disabled={!activeGameId}
                            onClick={goToActiveGameScore}
                        >
                            Active Game Score
                        </Button>
                    }
                </>
            }
            <div>
                {checkUserAccessByPermission(userPermissions, PagePermissionsDictionary.PreMatchImage) &&
                    selectedGameType !== GameTypes.SHOOTOUT &&
                    <Button
                        id="scoreboard"
                        disabled={!selectedGame}
                        color="facebook"
                        onClick={gotToPreMatchStats}
                    >
                        Pre-Match Image
                    </Button>
                }
                {checkUserAccessByPermission(userPermissions, PagePermissionsDictionary.PostMatch) &&
                    selectedGameType !== GameTypes.SHOOTOUT &&
                    <Button
                        id="scoreboard"
                        color="violet"
                        onClick={gotToPostMatch}
                    >
                        Post-Match
                    </Button>
                }
            </div>

        </div>
    );
};

export default ScheduleActiveButtons;
