import React, {Fragment, useEffect} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {PrivateRoute} from './components/PrivateRoute';
import Login from './screens/Login';
import Schedule from "./screens/Schedule";
import DartBoard from './screens/DartBoard/ClassicDartBoard/DartBoard';
import MatchContainer from './screens/MatchContainer/MatchContainer';
import {Statistics} from './screens/Statistics/Statistics'
import CmsPage from './screens/CMS/CmsPage/CmsPage';
import GameScores from './screens/DartBoard/components/GameScores/GameScores';
import {DeniedPage} from './screens/Errors/Denied';
import LiveScorePage from './screens/LiveScorePage/LiveScorePage';
import DartMatchStatsTable from './screens/DartMatchStatsTable/DartMatchStatsTable';
import {LeagueTablePage} from './screens/LeagueTablePage/LeagueTablePage';
import {ActiveGameScorePage} from './screens/ScoreboardPage';
import ShootOutDartBoard from "./screens/DartBoard/ShootOutDartBoard/ShootOutDartBoard";
import {ShootOutActiveGameScorePage} from "./screens/ShootOutScoreBoardPage";
import ShootOutMatchStats from "./screens/ShootOutMatchStatsTable/ShootOutMatchStatsTable";
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import PreMatchPage from "./screens/PreMatchPage/PreMatchPage";
import PostMatchPage from "./screens/PostMatchPage/PostMatchPage";
import StaticStatistic from "./screens/PreMatchPage/StaticStatistic/StaticStatistic";
import ReduxToastr from "react-redux-toastr";
import {getOnStartUp} from "./store/login/authSlice";
import {useAppDispatch} from "./hooks/hooks";
import {UserRole} from "./store/cms/usersNRoles/types";
import DailyResults from "./screens/DailyResults/DailyResults";
import PasswordIssues from "./screens/PasswordIssues/PasswordIssues";
import "rc-time-picker/assets/index.css";
import 'react-day-picker/lib/style.css';
import 'flatpickr/dist/themes/light.css'
import LoggingExceptions from "./screens/LoggingPages/Exceptions/Exceptions";
import Connections from "./screens/LoggingPages/Connections/Connections";
import Messages from "./screens/LoggingPages/Messages/Messages";

const AppContainer = () => {
    const dispatch = useAppDispatch();
    const renderDart = () => (<MatchContainer><DartBoard/></MatchContainer>);
    const renderShootOutDartBoard = () => (<MatchContainer><ShootOutDartBoard/></MatchContainer>);
    const renderNewDart = () => (<LiveScorePage/>);

    useEffect(() => {
        dispatch(getOnStartUp());
    }, [dispatch])


    return (

        <Fragment>
            <Switch>
                <PrivateRoute
                    exact path="/darts/livescore/:gameType?/:eventId?/:tourId?" render={renderNewDart}
                    roles={[UserRole.DCUser, UserRole.Client, UserRole.ReadonlyUser]}
                />
                <PrivateRoute
                    exact path="/schedule"
                    roles={[UserRole.DCUser, UserRole.Client, UserRole.ReadonlyUser]}
                    component={Schedule}
                />
                <PrivateRoute
                    exact path="/darts/:gameId"
                    roles={[UserRole.DCUser, UserRole.Client, UserRole.ReadonlyUser]} render={renderDart}
                />
                <PrivateRoute
                    exact path="/darts-adc/:gameId"
                    roles={[UserRole.DCUser, UserRole.Client, UserRole.ReadonlyUser]} render={renderDart}
                />
                <PrivateRoute
                    exact path="/darts-shootout/:gameId"
                    roles={[UserRole.DCUser, UserRole.Client, UserRole.ReadonlyUser]}
                    render={renderShootOutDartBoard}
                />
                <PrivateRoute path="/cms" component={CmsPage}/>
                <PrivateRoute path="/statistics" component={Statistics}/>
                <PrivateRoute path="/logging-exceptions" component={LoggingExceptions}/>
                <PrivateRoute path="/logging-connections" component={Connections}/>
                <PrivateRoute path="/logging-messages" component={Messages}/>
                <PrivateRoute path="/pre-match-animation/:gameId" component={PreMatchPage}/>
                <PrivateRoute path="/pre-match-stats/:gameId" component={StaticStatistic}/>
                <PrivateRoute path="/post-match-page" component={PostMatchPage}/>
                <PrivateRoute exact path="/game-scores-darts/:leagueId" component={GameScores}/>
                <PrivateRoute exact path="/active-game-scores/:gameType/:leagueId" component={ActiveGameScorePage}/>
                <PrivateRoute exact path="/shootout-active-game-scores/:gameId"
                              component={ShootOutActiveGameScorePage}/>
                <PrivateRoute exact path="/darts/leagues/:leagueId" component={LeagueTablePage}/>
                <PrivateRoute exact path="/match-stats-table/:gameType/:gameId" component={DartMatchStatsTable}/>
                <PrivateRoute exact path="/shootout-match-stats-table/:gameId/:tourId" component={ShootOutMatchStats}/>
                <PrivateRoute exact path="/daily-results/:tourId" component={DailyResults}/>
                <Route exact path='/denied' render={() => <DeniedPage/>}/>
                <Route exact path='/login' render={() => <Login/>}/>
                <Route exact path='/password-issues' render={() => <PasswordIssues/>}/>
                <Route path='*' render={() => <Redirect to='/login'/>}/>
            </Switch>
            <ReduxToastr
                timeOut={10000}
                newestOnTop={false}
                preventDuplicates={true}
                position="top-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
            />
        </Fragment>
    )
}

export default AppContainer;
