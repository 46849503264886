import {client} from "../../../../services/api.service";
import {IMatchStatusPayload} from "../types";
import {setCurrentGameStatus} from "../dartsCurrentGameSlice";
import {DartsGameTypes, GameTypes} from "../../../../enums/gameEvents";
import {clearGame} from "../../handler/gameHandlerSlice";
import {selectAdcGame, selectClassicGame} from "../../../pages/schedule/scheduleSlice";
import {GameStatuses} from "../../../../enums/gameStatus";
import {AppDispatch} from "../../../../store";

export const API_GAME_STATUS = "api/darts/game-status"

export const setMatchStatusForCertainGame = async (payload: IMatchStatusPayload, dispatch:AppDispatch): Promise<void> => {
    const {gameType, gameData, history, status, playerId} = payload;
    const createChangeStatusPayload = () => {
        if (status === GameStatuses.Walkover)
            return {
                gameId: gameData.gameId,
                status,
                date: gameData.date,
                playerId
            }
        else {
            return {
                gameId: gameData.gameId,
                status,
                date: gameData.date,
            }
        }
    }

    switch (gameType) {
        case GameTypes.ADC:
            const adcResponse = await client.put(API_GAME_STATUS, {...createChangeStatusPayload(),type:DartsGameTypes.ADC});
            dispatch(setCurrentGameStatus(gameData.status));
            dispatch(clearGame());
            dispatch(selectAdcGame(0))
            if (history) {
                history.push('/schedule')
            }
            return adcResponse.data;

        case GameTypes.SHOOTOUT:
            const shootOutResponse = await client.put(API_GAME_STATUS, {...createChangeStatusPayload(),type:DartsGameTypes.SHOOTOUT});
            dispatch(setCurrentGameStatus(gameData.status));
            dispatch(clearGame());
            if (history) {
                history.push('/schedule')
            }
            return shootOutResponse.data;

        default:
            const classicResponse = await client.put(API_GAME_STATUS, {...createChangeStatusPayload(),type:DartsGameTypes.CLASSIC});
            dispatch(setCurrentGameStatus(gameData.status));
            dispatch(clearGame());
            dispatch(selectClassicGame(0))
            if (history) {
                history.push('/schedule')
            }
            return classicResponse.data;
    }
};
