import React from "react";
import {useParams} from "react-router-dom";
import LeagueTable from "./LeagueTable/LeagueTable";
import ADCLogo from "../../img/ADC-Logo-White-Orange-slogan.png"
import "./styles.scss";

interface ILeagueTablePageProps {
}

export const LeagueTablePage: React.FC<ILeagueTablePageProps> = () => {
    const {leagueId: leagueIdParam} = useParams<{ leagueId: string }>();
    const leagueId = +leagueIdParam;


    return (
        <div className="league-table-page">
      <span className="img-container">
            <img alt='logo' src={process.env.PUBLIC_URL + '/modus-home-page-logo.png'} className="logo"></img>
          {/*<img alt='logo' src={ADCLogo} className="logo"></img>*/}
      </span>
            <div className="league-table-wrapper">
                <LeagueTable isShowDownLoadBtn={true} leagueId={leagueId}/>
            </div>
        </div>
    );
}

LeagueTablePage.displayName = "LeagueTablePage";
