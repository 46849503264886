import React from 'react';
import styles from './index.module.scss'
import {Pagination, Select} from "semantic-ui-react";

interface IAppPaginationProps {
    pageSize: number
    onChangePageSize: (value: any) => void
    activePage: number
    totalPages: number
    onChangePager: (page: any) => void
    isInvertColor?: boolean
}

const AppPagination: React.FC<IAppPaginationProps> = (
    {
        pageSize,
        onChangePageSize,
        activePage,
        totalPages,
        onChangePager,
        isInvertColor = false
    }) => {
    const pagerSelectOptions = [
        {key: 10, value: 10, text: "10 Items"},
        {key: 20, value: 20, text: "20 Items"},
        {key: 50, value: 50, text: "50 Items"},
    ]

    return (
        <div>
            <div className={styles.pagerInner}>
                <div>
                    <Select
                        onChange={(e, {value}) => onChangePageSize(value)}
                        value={pageSize}
                        defaultValue={pageSize}
                        className={styles.pagerSelect}
                        options={pagerSelectOptions}
                    />
                </div>
                <Pagination
                    inverted={isInvertColor}
                    floated="right"
                    size="mini"
                    activePage={activePage}
                    totalPages={totalPages}
                    onPageChange={(e, {activePage}) => onChangePager(activePage)}
                />
            </div>
        </div>
    );
};

export default AppPagination;
