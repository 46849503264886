import React from 'react';
import styles from "./index.module.scss"

interface LoggingHeaderProps {
    text:string
}
const LoggingHeader:React.FC<LoggingHeaderProps> = ({text}) => {
    return (
        <div className={styles.loggingHeader}>
            <div className={styles.container}>
                <div className={styles.headerText}>{text}</div>
            </div>
        </div>
    );
};

export default LoggingHeader;
