import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export const WEBSOCKET_CONNECT_TYPE = "webSockets/connect";
const initialState: {connection: boolean} = {
    connection: false
}

export const webSockets = createSlice({
    name: 'webSockets',
    reducers: {
        connect: (_) => {
            console.log('Connecting..')
        },
        isConnected: (state, action: PayloadAction<boolean>) => {
            state.connection = action.payload;
        },
    },
    initialState
})

export const {
    connect,
    isConnected
} = webSockets.actions;
export default webSockets.reducer;
