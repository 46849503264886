import React, { useState, useEffect, ReactNode } from 'react';

interface TimedComponentProps {
    component: ReactNode;
    startState: boolean;
}

const TimedComponent: React.FC<TimedComponentProps> = ({ component, startState }) => {
    const [visible, setVisible] = useState<boolean>(startState);

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(!visible);
        }, 2000);

        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return visible ? <>{component}</> : null;
};
export default TimedComponent