import moment from "moment/moment";
import {removeNameSeparator} from "../../../helpers/playersNames.helper";
import {useEffect, useState} from "react";
import {DropdownItemProps} from "semantic-ui-react";
import {getStatusForGame} from "../helpers/getStatusForGame";
import {DartsGameItem} from "../../../store/dartsGame/handler/types";
import {DartsADCGame, DartsStatuses} from "../../../store/pages/schedule/types";

export const useFormatGameOptions = (selectedTournament: number, games: DartsGameItem[], gamesADC: DartsADCGame[], statuses: DartsStatuses[]) => {
    const [gamesOptions, setGamesOption] = useState<DropdownItemProps[] | undefined>(undefined);
    const [adcGamesOption, setAdcGamesOption] = useState<DropdownItemProps[] | undefined>(undefined);
    const formatOptions = () => {
        if (selectedTournament) {
            const filteredGame = games?.filter((game: DartsGameItem) => game.fId === selectedTournament);
            const newGamesOptions = filteredGame?.map((game: DartsGameItem) => {
                const localDate = moment.utc(game?.date).toDate();
                const editedGameName = game?.name.split(' vs ').map((game) => {
                    return removeNameSeparator(game);
                }).join(' vs ');

                return {
                    key: game?.id,
                    value: game?.id,
                    text: `${editedGameName} (${moment(localDate)
                        .local()
                        .format("MMM.DD hh:mm a")}) ${getStatusForGame(game, statuses).toLowerCase()}`,
                };
            });
            newGamesOptions.forEach((game: { key: number, value: number, text: string }) => {
                removeNameSeparator(game.text)
            })
            setGamesOption(newGamesOptions);
        }
        const newAdcGamesOptions = gamesADC?.map((game) => {
            const localDate = moment.utc(game.date).toDate();
            const editedAdcGameName = game.name.split(' vs ').map((game) => {
                return removeNameSeparator(game);
            }).join(' vs ');

            return {
                key: game.id,
                value: game.id,
                text: `${editedAdcGameName} (${moment(localDate)
                    .local()
                    .format("MMM.DD hh:mm a")}) ${getStatusForGame(game, statuses).toLowerCase()}`,
            };
        });
        newAdcGamesOptions?.forEach((game: { key: number, value: number, text: string }) => {
            removeNameSeparator(game.text)
        })
        setAdcGamesOption(newAdcGamesOptions);
    }

    useEffect(() => {
        formatOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTournament, gamesADC]);

    return {adcGamesOption, gamesOptions}
}

