import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {client} from "../../../../services/api.service";
import {errorLogger} from "../../../../services/error-logger";
import {toastr} from "react-redux-toastr";
import {DartsPhase, IDartsPhase, IDartsPhaseCreatePayload} from "./types";
import {API_DARTS_PHASE} from "../../../../constants";

export interface IGetPhaseResponse {
    totalItems: 0,
    totalPages: 0,
    pageNum: 0,
    pageSize: 0,
    items: IDartsPhase[]
}

const initialState: DartsPhase = {
    phases: [] as IDartsPhase[],
    isLoading: true,
    isVisibleForm: false,
    editMode: false,
    editPhaseId: null,
    noContent: null,
    pageNum:1,
    pageSize:10,
    totalItems:0,
    totalPages:0
}

export const getDartsPhases = createAsyncThunk<IGetPhaseResponse,string>(
    'dartsPhases/get',
    async (query, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_DARTS_PHASE}?${query}`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch darts phases.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get Darts Phases: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)


export const createDartsPhase = createAsyncThunk<IDartsPhaseCreatePayload, IDartsPhaseCreatePayload>(
    'dartsPhase/post',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.post(API_DARTS_PHASE, payload);
            toastr.success("CMS", "Darts phase has been created");
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to create a phase.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Create darts phase: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)

export const editDartsPhase = createAsyncThunk<IDartsPhaseCreatePayload, IDartsPhaseCreatePayload>(
    'dartsPhase/edit',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`${API_DARTS_PHASE}`, payload);
            toastr.success("CMS", "Darts phase has been edited");
            return data;
        } catch (error) {
            console.log(error.message);
            toastr.error('CMS', 'Failed to edit a phase.')
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Edit darts phase: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)

export const deleteDartsPhase = createAsyncThunk<DartsPhase, number>(
    'dartsPhases/delete',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`${API_DARTS_PHASE}/${id}`);
            toastr.success("", "Darts phase has been deleted");
            return data;
        } catch (error) {
            toastr.info('CMS', error.response.data);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Delete darts phase: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog)
            return rejectWithValue(error.response.data);
        }
    }
)


export const cmsPhasesSlice = createSlice({
    name: 'cmsDartsPhases',
    reducers: {
        showPhaseForm: (state) => {
            state.isVisibleForm = true
        },
        hidePhaseForm: (state) => {
            state.isVisibleForm = false;
            state.editMode = false;
        },
        setEditModePhaseForm: (state, action: PayloadAction<number | null>) => {
            state.editMode = true;
            state.isVisibleForm = true;
            state.editPhaseId = action.payload;
        },
        setPageNumber: (state, action) => {
            state.pageNum = action.payload
        },
        changePageSize: (state, action) => {
            state.pageSize = action.payload
        }
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getDartsPhases.fulfilled, (state, action) => {
                state.phases = action.payload.items;
                state.noContent = false;
                state.isLoading = false;
                state.totalPages = action.payload.totalPages
            })
            .addCase(getDartsPhases.rejected, (state) => {
                state.isLoading = false;

            })
            .addCase(deleteDartsPhase.pending, (state) => {
                state.isVisibleForm = false;
                state.isLoading = true;
            })
            .addCase(deleteDartsPhase.fulfilled, (state, action) => {
                if (action.payload !== undefined) {
                    state.phases = state.phases.filter(
                        (phase) => phase.id !== action.meta.arg
                    );
                }
                state.isLoading = false;

            })
            .addCase(deleteDartsPhase.rejected, (state) => {
                state.isLoading = false;

            })
            .addCase(createDartsPhase.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createDartsPhase.fulfilled, (state, action) => {
                state.phases = state.phases.concat(action.payload);
                state.isLoading = false;
                state.isVisibleForm = false;
            })
            .addCase(createDartsPhase.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(editDartsPhase.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(editDartsPhase.fulfilled, (state, action) => {
                state.phases = state.phases.map((phase) => {
                    if (phase.id === action.payload.id) {
                        phase = action.payload
                    }
                    return phase
                })
                state.editMode = false;
                state.isLoading = false;
                state.isVisibleForm = false;
            })
            .addCase(editDartsPhase.rejected, (state: DartsPhase) => {
                state.isLoading = false;
            });
    },
});

export const {
    showPhaseForm,
    hidePhaseForm,
    setEditModePhaseForm,
    setPageNumber,
    changePageSize
} = cmsPhasesSlice.actions
export default cmsPhasesSlice.reducer;

