import {useMemo} from 'react';
import {IPhaseOptions} from "../../../../../../store/cms/darts/phases/types";
import {ISelectOption} from "../../../../../../store/cms/darts/tournaments/types";

const useTournamentsOptions = (allCompetitions: ISelectOption[], allGroups: ISelectOption[], allPhases: IPhaseOptions[]) => {

    const competitions = useMemo(() => {
        return allCompetitions?.map((comp: { id: any; name: any; }) => ({
            value: comp.id,
            text: comp.name,
        }))
    }, [allCompetitions])

    const groups = useMemo(() => {
        return allGroups?.map((group) => ({
            key: group.id,
            value: group.id,
            text: group.name,
        }))
    }, [allGroups])

    const phases = useMemo(() => {
        return allPhases?.map((phase) => ({
            key: phase.id,
            value: phase.id,
            text: phase.name,
        }))
    }, [allPhases])
    return (
        {competitions, groups, phases}
    );
};

export default useTournamentsOptions;
