import React, {useEffect, useState} from 'react';
import styles from './index.module.scss'
import LoggingHeader from "../components/LoggingHeader/LoggingHeader";
import {
    Icon,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow
} from "semantic-ui-react";

import AppPagination from "../../../components/Pagination/AppPagination";
import LoggingFilter from "../components/LoggingFilter/LoggingFilter";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import moment from "moment/moment";
import {changePageSize, deleteMessage, getMessages, setPageNum} from "../../../store/logging/loggingMessagesSlice";
import {buildQueryString} from "../../CMS/Darts/DartsGames/helpers/buildQueryString";
import LoggingConfirmModal from "../Exceptions/components/LoggingConfirmModal/LoggingConfirmModal";

const Messages = () => {
    const dispatch = useAppDispatch()
    const {pageNum, pageSize, totalPages, messages} = useAppSelector(state => state.loggingMessagesSlice)
    const [startDate, setStartDate] = useState<string | Date>(new Date());
    const [endDate, setEndDate] = useState<string | Date>(new Date());
    const [search, setSearch] = useState("");
    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
    const [deletingRecord, setDeletingRecord] = useState<string | number>("");

    useEffect(() => {
        const query = buildQueryString({pageNum, pageSize})
        dispatch(getMessages(query))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const onChangePageSize = (size: number) => {
        dispatch(changePageSize(size))
        const query = buildQueryString({pageNum, pageSize: size, search})
        dispatch(getMessages(query))
    }

    const onChangePageNum = (pageNum: number) => {
        dispatch(setPageNum(pageNum))
        const query = buildQueryString({pageNum, pageSize, search})
        dispatch(getMessages(query))
    }

    const onSearch = (search: string) => {
        setSearch(search)
        const query = buildQueryString({pageNum: 1, pageSize, search})
        dispatch(getMessages(query))
        dispatch(setPageNum(1))
    }

    const onChangeStartDate = (date: string) => {
        const from = moment(date).utc().format()
        const to = moment(endDate).utc().format()
        const query = buildQueryString({pageNum, pageSize, search, from, to})
        setStartDate(date)
        if (!from.includes("Invalid") && !to.includes("Invalid")) {
            dispatch(getMessages(query))
        }
    }

    const onChangeEndDate = (date: string) => {
        const from = moment(startDate).utc().format()
        const to = moment(date).utc().format()
        const query = buildQueryString({pageNum, pageSize, search, from, to})
        setEndDate(date)
        if (!from.includes("invalid") && !to.includes("invalid")) {
            dispatch(getMessages(query))
        }
    }

    const onDeleteMessage = () => {
        dispatch(deleteMessage(deletingRecord))
        setDeletingRecord("")
        setIsShowConfirmModal(false)
    }

    const showConfirmModal = (messageId: string | number) => {
        setDeletingRecord(messageId)
        setIsShowConfirmModal(true)
    }
    const closeConfirmModal = () => {
        setDeletingRecord("")
        setIsShowConfirmModal(false)
    }
    return (
        <div className={styles.page}>
            {isShowConfirmModal &&
                <LoggingConfirmModal
                    onDelete={onDeleteMessage}
                    text={`Are you sure you want to delete message with id ${deletingRecord}`}
                    onClose={closeConfirmModal}
                />}
            <LoggingHeader text={"Messages"}/>
            <div className={styles.container}>
                <LoggingFilter
                    showRefresh={true}
                    search={search}
                    setSearch={onSearch}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={onChangeStartDate}
                    setEndDate={onChangeEndDate}
                />
            </div>
            <div className={styles.tableInner}>
                <Table celled inverted>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell textAlign={'center'}>Id</TableHeaderCell>
                            <TableHeaderCell textAlign={'center'}>Time</TableHeaderCell>
                            <TableHeaderCell textAlign={'center'}>Type</TableHeaderCell>
                            <TableHeaderCell textAlign={'center'}>Message</TableHeaderCell>
                            <TableHeaderCell textAlign={'center'}></TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {messages.map(message => {
                            return (
                                <TableRow>
                                    <TableCell textAlign='center'>{message.id}</TableCell>
                                    <TableCell
                                        textAlign='center'>{moment(message.date).format("YYYY-DD-MM HH:mm:ss")}</TableCell>
                                    <TableCell textAlign='center'>http</TableCell>
                                    <TableCell textAlign='center'>{message.message}</TableCell>
                                    <TableCell textAlign='center'>
                                        <button
                                            onClick={() => showConfirmModal(message.id)}
                                            className={styles.controlBtn}>
                                            <Icon color={"red"} name={"trash alternate"}/>
                                        </button>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                <div className={styles.pagination}>
                    <AppPagination
                        isInvertColor={true}
                        pageSize={pageSize}
                        onChangePageSize={onChangePageSize}
                        activePage={pageNum}
                        totalPages={totalPages}
                        onChangePager={onChangePageNum}
                    />
                </div>
            </div>
        </div>
    );
};

export default Messages;
