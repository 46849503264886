import {ISelectOption} from "../darts/tournaments/types";

export enum UserRole {
    Admin = 1,
    DCUser = 2,
    Client = 3,
    ReadonlyUser = 4
}

export enum UserRoles {
    StatsUser = 'StatsUser',
    CrmUser = 'CrmUser',
    Viewer = 'Viewer',
    Scoring = 'Scoring',
    Admin = 'Admin',
    ClientAdmin = 'ClientAdmin'

}

export interface IUserRolesState {
    roles: IRole[];
    allUsers: ICMSUser[];
    allClients: ISelectOption[],
    user: ICMSUser;
    usersLoader: boolean;
    rolesLoader: boolean;
    createMode: boolean;
    editMode: boolean;
    filterValues: FilterValues;
    totalItems: number,
    totalPages: number,
    pageNum: number,
    pageSize:number
    clientId: any
    allowCRUD: boolean
}

export type FilterValues = {
    from: string;
    search: string;
    status: number;
    pNum: number;
    pSize: number;
    sort: boolean;
}

export interface IUser {
    id: number,
    name: string,
    email?: string,
    roles: string[] | null
}

export interface ICMSUser {
    id: number,
    name: string,
    email: string,
    roles: string[] | null
    isAllowed: boolean,
    is2FA: boolean,
    clientId: number
}

export interface IUserPayload {
    id?: number;
    roles: string[] | null;
    email: string;
    name: string;
    clientId: number | null
    is2FA: boolean
    permissions: string[]
}

export interface IRole {
    id: number;
    name: string;
    description: string;
}

export interface IUserFormData {
    userRole: string;
    userName: string;
    userEmail: string;
    userPassword?: string;
}
