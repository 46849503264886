import React, {useEffect, useMemo, useState} from "react";
import {Button, Icon, Input, Select, Table} from "semantic-ui-react";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {
    deleteListener, getClients,
    getListenerInit,
    getListeners,
    IListener, setListenerClientId,
    setPageNumber
} from "../../../../store/cms/listeners/listenersSlice";
import {buildQueryString} from "../../Darts/DartsGames/helpers/buildQueryString";
import DartsDeleteModal from "../../../../components/DartsPlayerTableModal/dartsDeleteModal";
import CheckboxCms from "../../../../components/CheckboxCms/CheckboxCms";
import styles from './index.module.scss'
import ListenersForm from "./ListenerForm/ListenersForm";
import {useDebounce} from "../../../../hooks/useDebounce";
import AppPagination from "../../../../components/Pagination/AppPagination";
import {changePageSize} from "../../../../store/cms/darts/games/cmsGamesSlice";

const Listeners = () => {
    const dispatch = useAppDispatch()
    const {
        listeners,
        pageSize,
        pageNum,
        totalPages,
        noContent,
        clientId,
        clients
    } = useAppSelector(state => state.listeners)
    const {isRenderControls} = useAppSelector((state) => state.auth);
    const [showListenerForm, setShowListenerForm] = useState(false);
    const [editedListener, setEditedListener] = useState<IListener | null>(null);
    const [searchWord, setSearchWord] = useState("");
    const [sortField, setSortField] = useState<string>('name');
    const [sortDirection, setSortDirection] = useState<'ascending' | 'descending'>('ascending');

    useEffect(() => {
        getInitData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getInitData = () => {
        dispatch(getClients())
        dispatch(getListeners(buildQueryString({pageNum, pageSize})))
        dispatch(getListenerInit())
    }
    const generateButtons = (listener: IListener) => {
        if (isRenderControls) {
            return (
                <div className={styles.tableControls}>
                    <DartsDeleteModal
                        id={listener.id}
                        deleteAction={() => dispatch(deleteListener(listener.id))}
                        confirmText={"Are you sure you want to delete this listener?"}
                        titleModal={"Delete the listener"}
                    />
                    <Icon
                        onClick={() => onEditListener(listener)}
                        name='pencil alternate'
                        link
                        size={"small"}
                    />
                </div>
            );
        }
    };

    const onEditListener = (listener: IListener) => {
        setEditedListener(listener)
        setShowListenerForm(true)
    }

    const closeForm = () => {
        setShowListenerForm(false)
    }

    const onChangePager = (activePage: string | number | undefined) => {
        dispatch(setPageNumber(activePage))
        const params = {
            pageNum: activePage,
            pageSize,
            sortField,
            sort: sortDirection === "ascending" ? 0 : 1,
            search: searchWord,
            clientId
        }
        dispatch(getListeners(buildQueryString(params)))
    }

    const onSearch = () => {
        const params = {
            pageNum: 1,
            pageSize,
            sortField,
            sort: sortDirection === "ascending" ? 0 : 1,
            search: searchWord,
            clientId
        }
        dispatch(getListeners(buildQueryString(params)))
    }

    const handleSort = (field: string) => {
        const direction = sortField === field && sortDirection === 'ascending' ? 'descending' : 'ascending';
        setSortField(field);
        setSortDirection(direction);
        const params = {
            pageNum,
            pageSize,
            sortField: field,
            sort: direction === "ascending" ? 0 : 1,
            search: searchWord,
            clientId
        }
        dispatch(getListeners(buildQueryString(params)))
    };

    const handleSelectClient = (client: any) => {
        dispatch(setListenerClientId(client))
        const params = {
            pageNum: 1,
            pageSize,
            sortField,
            sort: sortDirection === "ascending" ? 0 : 1,
            search: searchWord,
            clientId: client
        }
        dispatch(getListeners(buildQueryString(params)))
    }

    useDebounce(onSearch, searchWord, 500)

    const clientOptions = useMemo(() => {
        const options = clients.map(client => {
            return {key: client.id, value: client.id, text: client.name}
        })
        options.unshift({key: 0, value: 0, text: "All"})
        return options
    }, [clients]);

    const onChangePageSize = (value: any) => {
        dispatch(changePageSize(value))
        const params = {
            pageNum,
            pageSize: value,
            sortField,
            sort: sortDirection === "ascending" ? 0 : 1,
            search: searchWord,
            clientId
        }
        dispatch(getListeners(buildQueryString(params)))
    }

    return (
        <>
            <div className={styles.pageControls}>
                <div className={styles.filterInner}>
                    <Select
                        options={clientOptions}
                        value={clientId}
                        onChange={(e, {value}) => {
                            handleSelectClient(value)
                        }}
                        placeholder='Select client'
                    />
                </div>
                <Input
                    onChange={(e) => setSearchWord(e.target.value)}
                    icon='search'
                    placeholder='Search...'
                />
                {isRenderControls && <Button
                    onClick={() => setShowListenerForm(true)}
                    color="blue"
                >
                    Add
                </Button>
                }
            </div>
            <Table sortable celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell
                            textAlign="center"
                        >
                            Id
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={sortField === 'name' ? sortDirection : undefined}
                            onClick={() => handleSort('name')}
                            textAlign="center"
                        >
                            Name
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={sortField === 'email' ? sortDirection : undefined}
                            onClick={() => handleSort('email')}
                            textAlign="center"
                        >
                            Email
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                            IP
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                            Socket key
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                            Active
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center"/>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {listeners?.map(listener => {
                        return (
                            <Table.Row>
                                <Table.Cell textAlign="center">
                                    {listener.id}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    {listener.name}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    {listener.email}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    {listener.whiteIPs}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    {listener.socketKey}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    <CheckboxCms value={listener.isActive} isReadyOnly={true}/>
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    {generateButtons(listener)}
                                </Table.Cell>
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
            {showListenerForm &&
                <ListenersForm
                    editedListener={editedListener}
                    setEditedListener={setEditedListener}
                    closeForm={closeForm}
                />}
            {(noContent || listeners.length === 0) && <div className={styles.noContent}>There no listeners</div>}
            {listeners.length > 0 &&
                <AppPagination
                    activePage={pageNum}
                    pageSize={pageSize}
                    totalPages={totalPages}
                    onChangePager={onChangePager}
                    onChangePageSize={onChangePageSize}
                />
            }
        </>
    );
}

export default Listeners;
