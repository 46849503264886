import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {API_MESSAGES} from "../../constants";
import {toastr} from "react-redux-toastr";
import {client} from "../../services/api.service";

interface IGetMessagesResponse {
    totalItems: 0,
    pageNum: 0,
    pageSize: 0,
    message: "string",
    isOK: true,
    sort: 0,
    sortField: string,
    search: string,
    items: ILoggingItem[],
    totalPages: number

}

interface ILoggingMessagesState {
    totalItems: number,
    pageNum: number,
    pageSize: number,
    messages: ILoggingItem[]
    totalPages: number
}

export interface ILoggingItem {
    id: number,
    type: string,
    message: string,
    source: string,
    date: string
}

const initialState: ILoggingMessagesState = {
    pageNum: 1,
    pageSize: 10,
    totalItems: 0,
    totalPages: 0,
    messages: []

}

export const getMessages = createAsyncThunk<IGetMessagesResponse, string>(
    'logging/get-messages',
    async (query, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_MESSAGES}/?${query}`);
            return data
        } catch (e) {
            toastr.error('Logging get messages:', `${e.response.data}`);
            return rejectWithValue(e.response.data)
        }
    }
)


export const deleteMessage = createAsyncThunk<{ id: number | string }, string | number>(
    'logging/delete-message',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`${API_MESSAGES}/${id}`);
            return data
        } catch (e) {
            toastr.error('Logging delete message', `${e.response.data}`);
            return rejectWithValue(e.response.data)
        }
    }
)


export const loggingMessagesSlice = createSlice({
    name: 'loggingMessages',
    reducers: {
        setPageNum: (state, action) => {
            state.pageNum = action.payload
        },
        changePageSize:(state,action) => {
            state.pageSize = action.payload
        }
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getMessages.pending, (state: ILoggingMessagesState) => {

            })
            .addCase(getMessages.fulfilled, (state: ILoggingMessagesState, action) => {
                state.messages = action.payload.items
                state.totalPages = action.payload.totalPages
            })

            .addCase(deleteMessage.fulfilled, (state: ILoggingMessagesState, action) => {
                state.messages = state.messages.filter(exc => exc.id !== action.meta.arg)
            })

    }
})
export const {setPageNum,changePageSize} = loggingMessagesSlice.actions
export default loggingMessagesSlice.reducer;



