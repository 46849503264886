import React from 'react';
import { useHistory } from 'react-router-dom';

import './index.scss';

const DeniedPage = () => {
  const history = useHistory();

  const message = "You are already logged into another tab/browser/device - please log out to proceed here.";
  const handleRedirect = () => {
    history.push('/login')
  };

  return (
    <div className='denied-page__container'>
      <div className='denied-page__message'>{message}</div>
      <div className='denied-page__backward-btn' onClick={handleRedirect}>Log in as another user</div>
    </div>)
};

export { DeniedPage }
