import moment from "moment";
import React, {useEffect} from "react";
import {Table, Button, Icon} from "semantic-ui-react";
import DartsDeleteModal from "../../../../components/DartsPlayerTableModal/dartsDeleteModal";
import DartsPhaseForm from "./DartsPhaseForm/DartsPhaseForm";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {
    changePageSize,
    deleteDartsPhase,
    getDartsPhases,
    setEditModePhaseForm, setPageNumber, showPhaseForm
} from "../../../../store/cms/darts/phases/cmsPhasesSlice";
import {IWeek} from "../../../../store/cms/darts/phases/types";
import {buildQueryString} from "../DartsGames/helpers/buildQueryString";
import styles from "./style.module.scss"
import AppPagination from "../../../../components/Pagination/AppPagination";


const DartsPhase = () => {
    const dispatch = useAppDispatch();
    const {
        isVisibleForm,
        phases,
        noContent,
        pageNum,
        pageSize,
        totalPages
    } = useAppSelector((state) => state.cmsPhases);
    const {isRenderControls} = useAppSelector((state) => state.auth);
    const getDateRange = (week: IWeek) => {
        return `${moment(week.from).format("LL")} - ${moment(week.to).format("LL")}`;
    };

    useEffect(() => {
        dispatch(getDartsPhases(buildQueryString({pageNum, pageSize})));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const onChangePager = (activePage: string | number | undefined) => {
        dispatch(setPageNumber(activePage))
        const params = {pageNum: activePage, pageSize}
        dispatch(getDartsPhases(buildQueryString(params)));
    }
    const onChangePageSize = (value: any) => {
        dispatch(changePageSize(value))
        const params = {pageNum, pageSize: value}
        dispatch(getDartsPhases(buildQueryString(params)));
    }
    return (
        <div>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                {isRenderControls && <Button onClick={() => dispatch(showPhaseForm())} id="add_phase" color="green">
                    Add
                </Button>
                }
            </div>
            {noContent === true ? (
                <div className="schedule-info-block">No phases</div>
            ) : (
                <Table basic celled structured>
                    <Table.Header>
                        <Table.Row>
                            {isRenderControls &&
                                <Table.HeaderCell textAlign="center">Id</Table.HeaderCell>
                            }
                            <Table.HeaderCell textAlign="center">Name</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Week</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Year</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Dates</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2"></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {!!phases?.length &&
                            phases?.map((phase) => {
                                const rowSpanValue = phase?.weeks?.length + 1;
                                return (
                                    <>
                                        <Table.Row key={phase?.id}>
                                            {isRenderControls &&
                                                <Table.Cell textAlign="center" rowSpan={`${rowSpanValue}`}>
                                                    {phase?.id}
                                                </Table.Cell>
                                            }
                                            <Table.Cell textAlign="center" rowSpan={`${rowSpanValue}`}>
                                                {phase?.name}
                                            </Table.Cell>
                                        </Table.Row>
                                        {phase?.weeks?.map((week: IWeek, index: number) => {
                                            if (index === 0) {
                                                return (
                                                    <Table.Row>
                                                        <Table.Cell textAlign="center">
                                                            {week?.number}
                                                        </Table.Cell>
                                                        <Table.Cell textAlign="center">
                                                            {week?.year}
                                                        </Table.Cell>
                                                        <Table.Cell textAlign="center">
                                                            {getDateRange(week)}
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            textAlign="center"
                                                            rowSpan={`${rowSpanValue}`}
                                                        >
                                                            {isRenderControls && <div className={styles.tableControls}>
                                                                <DartsDeleteModal
                                                                    id={phase.id!}
                                                                    deleteAction={(id: number) => dispatch(deleteDartsPhase(id))}
                                                                    confirmText={
                                                                        "Are you sure you want to delete your phase?"
                                                                    }
                                                                    titleModal={"Delete your Phase"}
                                                                />
                                                                <Icon
                                                                    name='pencil alternate'
                                                                    link
                                                                    color="grey"
                                                                    size="small"
                                                                    onClick={() =>
                                                                        dispatch(setEditModePhaseForm(phase.id!))
                                                                    }
                                                                >
                                                                </Icon>
                                                            </div>
                                                            }
                                                        </Table.Cell>
                                                    </Table.Row>
                                                );
                                            } else {
                                                return (
                                                    <Table.Row>
                                                        <Table.Cell textAlign="center">
                                                            {week.number}
                                                        </Table.Cell>
                                                        <Table.Cell textAlign="center">
                                                            {week.year}
                                                        </Table.Cell>
                                                        <Table.Cell textAlign="center">
                                                            {getDateRange(week)}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                );
                                            }
                                        })}
                                    </>
                                );
                            })}
                    </Table.Body>
                </Table>
            )}
            <AppPagination
                activePage={pageNum}
                pageSize={pageSize}
                totalPages={totalPages}
                onChangePager={onChangePager}
                onChangePageSize={onChangePageSize}
            />
            {isVisibleForm && (
                <DartsPhaseForm
                    dartsPhase={phases}
                    getDateRange={getDateRange}
                />
            )}
        </div>
    );
};

export default DartsPhase;
