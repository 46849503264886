import React, {useEffect, useMemo, useState} from "react";
import {Table, Button, Icon, Input, Select} from "semantic-ui-react";
import DartsDeleteModal from "../../../../components/DartsPlayerTableModal/dartsDeleteModal";
import DimmerLoader from "../../../../components/DImmerLoader/DimmerLoader";
import DartsTournamentsTableForm from "./DartsTournamentsForm/dartsTournamentsTableForm";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {
    getDartsTournamentsInit,
    setEditTournamentMode,
    showTournamentForm,
    getDartsTournaments, deleteDartsTournament, setPageNumber, setFilter, clearFilter, changePageSize
} from "../../../../store/cms/darts/tournaments/cmsTournamentsSlice";
import styles from "./style.module.scss"
import {buildQueryString} from "../DartsGames/helpers/buildQueryString";
import {useDebounce} from "../../../../hooks/useDebounce";
import AppPagination from "../../../../components/Pagination/AppPagination";

const DartsTournaments = () => {
    const dispatch = useAppDispatch();
    const {
        isVisibleForm,
        isLoading,
        noContent,
        dartsTournaments,
        totalPages,
        pageNum,
        pageSize,
        phaseOptions,
        competitionOptions,
        filter
    } = useAppSelector((state) => state.cmsTournaments);
    const {isRenderControls} = useAppSelector((state) => state.auth);
    const [searchWord, setSearchWord] = useState("");
    const [sortField, setSortField] = useState<string>('competitionName');
    const [sortDirection, setSortDirection] = useState<'ascending' | 'descending'>('ascending');
    const [weeks, setWeeks] = useState<{ key: number; value: number; text: string; }[]>([]);

    useEffect(() => {
        const params = {pageNum, pageSize, sortField, sort: sortDirection === "ascending" ? 0 : 1}
        dispatch(getDartsTournaments(buildQueryString({...params, ...filter})));
        dispatch(getDartsTournamentsInit())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const onChangePager = (activePage: string | number | undefined) => {
        dispatch(setPageNumber(activePage))
        const params = {pageNum: activePage, pageSize, search: searchWord}
        dispatch(getDartsTournaments(buildQueryString(params)));
    }

    const handleSort = (field: string) => {
        const direction = sortField === field && sortDirection === 'ascending' ? 'descending' : 'ascending';
        setSortField(field);
        setSortDirection(direction);
        const params = {
            pageNum,
            pageSize,
            sortField: field,
            sort: direction === "ascending" ? 0 : 1,
            search: searchWord
        }
        dispatch(getDartsTournaments(buildQueryString({...params, ...filter})))
    };

    const onSearch = () => {
        const params = {pageNum, pageSize, sortField, sort: sortDirection === "ascending" ? 0 : 1, search: searchWord}
        dispatch(getDartsTournaments(buildQueryString({...params, ...filter})))
    }

    useDebounce(onSearch, searchWord, 500)

    const phases = useMemo(() => {
        const options = phaseOptions.map(phase => {
            return {key: phase.id, value: phase.id, text: phase.name}
        })
        options.unshift({key: 0, value: 0, text: "Clear"})
        return options
    }, [phaseOptions]);

    const competitions = useMemo(() => {
        const options = competitionOptions.map(phase => {
            return {key: phase.id, value: phase.id, text: phase.name}
        })
        options.unshift({key: 0, value: 0, text: "Clear"})
        return options
    }, [competitionOptions]);

    const setFilterValue = (name: "phase" | "week" | "competition", value: any) => {
        dispatch(setFilter({name, value}))
        const params = {
            pageNum: 1,
            pageSize,
            sortField,
            sort: sortDirection === "ascending" ? 0 : 1,
            search: searchWord
        }
        const filters = {...filter, [name]: value}
        dispatch(getDartsTournaments(buildQueryString({...params, ...filters})))

    }

    const onSelectPhase = (value: any) => {
        const phase = phaseOptions.find(phase => phase.id === value)
        if (phase) {
            const weekOptions = phase.items.map(week => {
                return {key: week.id, value: week.id, text: week.name}
            })
            weekOptions.unshift({key: 0, value: 0, text: "Clear"})
            setWeeks(weekOptions)
        }
    }
    const onChangePageSize = (value: any) => {
        dispatch(changePageSize(value))
        const params = {
            pageNum,
            pageSize: value,
            sortField,
            sort: sortDirection === "ascending" ? 0 : 1,
            search: searchWord
        }
        dispatch(getDartsTournaments(buildQueryString({...params, ...filter})))
    }

    return (
        <div>
            <div className={styles.tableControls}>
                {!!filter.phase && <Select
                    options={weeks}
                    value={filter.week}
                    onChange={(e, {value}) => {
                        setFilterValue("week", value)
                    }}
                    placeholder='Select week'
                />}
                <Select
                    options={phases}
                    value={filter.phase}
                    onChange={(e, {value}) => {
                        setFilterValue("phase", value)
                        onSelectPhase(value)
                    }}
                    placeholder='Select phase'
                />
                <Select
                    options={competitions}
                    value={filter.competition}
                    onChange={(e, {value}) => {
                        setFilterValue("competition", value)
                    }}
                    placeholder='Select competition'
                />
                <Input
                    onChange={(e) => setSearchWord(e.target.value)}
                    icon='search'
                    placeholder='Search...'
                />
                <Button
                    onClick={() => dispatch(clearFilter())}
                    color="green">
                    Clear filter
                </Button>
                {isRenderControls && <Button onClick={() => dispatch(showTournamentForm())} color="green">
                    Add
                </Button>
                }
            </div>
            {noContent ? (
                <div className="schedule-info-block">No tournaments</div>
            ) : (
                <Table sortable celled>
                    {isLoading && <DimmerLoader/>}
                    <Table.Header>
                        <Table.Row>
                            {isRenderControls &&
                                <Table.HeaderCell textAlign="center">
                                    Id
                                </Table.HeaderCell>
                            }
                            <Table.HeaderCell
                                sorted={sortField === 'competitionName' ? sortDirection : undefined}
                                onClick={() => handleSort('competitionName')}
                                textAlign="center"
                            >
                                Competition
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={sortField === 'phaseName' ? sortDirection : undefined}
                                onClick={() => handleSort('phaseName')}
                                textAlign="center">
                                Phase
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={sortField === 'weekName' ? sortDirection : undefined}
                                onClick={() => handleSort('weekName')}
                                textAlign="center"
                            >
                                Week
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={sortField === 'groupName' ? sortDirection : undefined}
                                onClick={() => handleSort('groupName')}
                                textAlign="center">
                                Group
                            </Table.HeaderCell>
                            <Table.HeaderCell/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {!noContent && dartsTournaments?.map((tour) => {
                            return (
                                <Table.Row key={tour.id}>
                                    {isRenderControls &&
                                        <Table.Cell textAlign="center">{tour.id}</Table.Cell>
                                    }
                                    <Table.Cell textAlign="center">{tour.competitionName}</Table.Cell>
                                    <Table.Cell textAlign="center">{tour.phaseName}</Table.Cell>
                                    <Table.Cell textAlign="center">{tour.weekName}</Table.Cell>
                                    <Table.Cell textAlign="center">{tour.groupName}</Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {isRenderControls && <div className={styles.tableControls}>
                                            <DartsDeleteModal
                                                id={tour.id}
                                                deleteAction={(id: number) => dispatch(deleteDartsTournament(id))}
                                                confirmText={
                                                    "Are you sure you want to delete your tournament?"
                                                }
                                                titleModal={"Delete your Tournament"}
                                            />
                                            <Icon
                                                onClick={() =>
                                                    dispatch(setEditTournamentMode(tour.id))
                                                }
                                                name='pencil alternate'
                                                link
                                                color="grey"
                                                size="small"
                                            >
                                            </Icon>
                                        </div>}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            )}
            {!!dartsTournaments && (
                <AppPagination
                    activePage={pageNum}
                    pageSize={pageSize}
                    totalPages={totalPages}
                    onChangePager={onChangePager}
                    onChangePageSize={onChangePageSize}
                />
            )}
            {isVisibleForm && (
                <DartsTournamentsTableForm
                    tournamentsIds={[]}
                />
            )}
        </div>
    );
};

export default DartsTournaments;
