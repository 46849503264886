import React, {useEffect, useRef, useState} from "react";
import {Button, Container, Grid} from "semantic-ui-react";
import Dartboard from "./components/Dartbord/dartboard_lib";
import {PlayerLiveStatsPanel} from "./components/PlayerLiveStatsPanel/PlayerLiveStatsPanel";
import {DartTimeline} from "./components/DartTimeline/DartTimeline";
import {DartboardHeader} from "./components/DartboardHeader/DartboardHeader";
import {GameStatuses} from "../../enums/gameStatus";
import {useAppSelector} from "../../hooks/hooks";
import {OnShotForLiveScore} from "../../enums/gameEvents";
import {getCSVStatistics} from "./helpers/getCSVStatistics";
import {setHighlightBedOnLastShot} from "./helpers/setLastShotAndHighlightBed";
import "./styles.scss";

const NewDartBoard: React.FC = React.memo(() => {
    const activeSidebarEvent = useAppSelector((state) => state.liveScoreInfo.activeSideBarEvent);
    const lastLiveShot = useAppSelector((state) => state.liveScoreInfo.lastShot);
    const {isAdc, allInformation} = useAppSelector((state) => state.liveScoreInfo);
    const {liveScore: {statistics, header: {status}}} = allInformation
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [lastShot, setLastShot] = useState<string | undefined>(lastLiveShot?.message);

    const liveShot = {
        bed: lastLiveShot?.bed,
        ring: lastLiveShot?.ring,
        message: lastLiveShot?.message,
    } as OnShotForLiveScore;

    const dartboardRef = useRef<any>(null);

    useEffect(() => {
        if (dartboardRef.current.innerHTML) {
            return;
        }
        const dartboard = new Dartboard(`#${dartboardRef.current.id}`);
        dartboard.render();
    }, []);


    useEffect(() => {
        if (!lastLiveShot) {
            return;
        }
        if (liveShot) {
            setHighlightBedOnLastShot({setLastShot, liveShot, dartboardRef})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastLiveShot]);

    return (
        <Container>
            <Container>
                <DartboardHeader/>
            </Container>
            <Grid container columns={3}>
                <Grid.Row>
                    <Grid.Column mobile={8} computer={4}>
                        <Container className="panel left-panel">
                            <PlayerLiveStatsPanel statistics={statistics.groups[0]}/>
                        </Container>
                    </Grid.Column>
                    <Grid.Column mobile={16} computer={8}>
                        <Container className="panel darts">
                            {lastShot && <div className="darts__live">{lastShot}</div>}
                            <div
                                id="dartboard"
                                ref={dartboardRef}
                                className="darts__container darts__container-center">

                            </div>
                            {status === GameStatuses.End && (
                                <div className="download-csv-btn">
                                    <Button
                                        color="red"
                                        loading={isLoading}
                                        onClick={() => getCSVStatistics({
                                                setIsLoading,
                                                isAdc,
                                                activeSidebarEvent
                                            }
                                        )}>
                                        Download Match Stats
                                    </Button>
                                </div>
                            )}
                        </Container>
                    </Grid.Column>
                    <Grid.Column mobile={8} computer={4}>
                        <Container className="panel right-panel">
                            <PlayerLiveStatsPanel statistics={statistics.groups[1]}/>
                        </Container>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Container>
                <DartTimeline/>
            </Container>
        </Container>
    );
})

export default NewDartBoard;
NewDartBoard.displayName = "NewDartBoard";
