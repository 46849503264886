import {setPlayersEditPhotoMode, setPlayersIsShowModal} from "../../../../../store/cms/darts/players/cmsPlayersSlice";
import {client} from "../../../../../services/api.service";
import {errorLogger} from "../../../../../services/error-logger";
import {AppDispatch} from "../../../../../store";
import React from "react";
import {API_PLAYERS_PHOTO} from "../../../../../constants";

interface IShowFullSizePhoto {
    id: number;
    setIsExistPhoto: React.Dispatch<React.SetStateAction<string>>
    dispatch: AppDispatch;
}
export const showFullSizePhoto = async ({id, setIsExistPhoto, dispatch}: IShowFullSizePhoto) => {
    try {
        setIsExistPhoto('');
        dispatch(setPlayersIsShowModal(true))
        dispatch(setPlayersEditPhotoMode(true))
        const playersPhoto = await client.get(`${API_PLAYERS_PHOTO}/${id}`)
        setIsExistPhoto(playersPhoto.data)

    } catch (error) {
        if (error instanceof Error) {
            console.error(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `getPlayerPhotoById: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog)
        }
    }
}
