import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {Button, Form, Icon, Input, Label, Message, Select} from "semantic-ui-react";
import {yupResolver} from "@hookform/resolvers/yup";
import {IWeekOption} from "../../../../../store/cms/darts/phases/types";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {
    createDartsTournament, editDartsTournament,
    hideTournamentForm
} from "../../../../../store/cms/darts/tournaments/cmsTournamentsSlice";
import {
    ICreateTournamentPayload,
    IDartsTour,
    IEditTournamentPayload
} from "../../../../../store/cms/darts/tournaments/types";
import {checkIsExistTournament} from "./helpers/checkIsExistTournament";
import useTournamentsOptions from "./helpers/useTournamentsOptions";
import * as yup from "yup";
import './style.scss';
import {toastr} from "react-redux-toastr";

interface ITournamentsFormProps {
    tournamentsIds: IDartsTour[],
}

const DartsTournamentsTableForm: React.FC<ITournamentsFormProps> = (
    {
        tournamentsIds,
    }) => {
    const dispatch = useAppDispatch();
    const {
        editMode,
        editTournamentId,
        competitionOptions,
        groupOptions,
        phaseOptions,
        dartsTournaments
    } = useAppSelector((state) => state.cmsTournaments);
    const [selectedPhaseId, setSelectedPhaseId] = useState<any>(null);
    const [weeksOptions, setWeeksOptions] = useState<any[]>([]);
    const [tourTitle, setTourTitle] = useState<any>({
        phase: "",
        week: "",
        group: ""
    });
    const {
        phases, groups, competitions
    } = useTournamentsOptions(competitionOptions, groupOptions, phaseOptions)


    useEffect(() => {
        setDefaultFormValues()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode, editTournamentId]);


    const getAllWeeks = () => {
        const allWeek: IWeekOption[] = [];
        phaseOptions?.map((phase) => {
            return phase?.items?.forEach((week) => {
                allWeek.push(week);
            });
        });
        return allWeek;
    };

    const setDefaultFormValues = () => {
        if (editMode && editTournamentId) {
            const editTour = dartsTournaments.find(
                (tour: { id: number; }) => tour.id === editTournamentId
            );

            const defaultCompetitionsOptions = competitionOptions.find(
                (competition) => competition.id === editTour!.competitionId
            );
            const defaultWeeksOptions = getAllWeeks()?.find(
                (option) => option.id === editTour!.weekId
            );

            const defaultPhasesOptions = phaseOptions?.find(
                (option) => option.id === editTour!.phaseId
            );
            const defaultGroupOptions = groupOptions.find(
                (option) => option.id === editTour!.groupId
            );
            setValue("competitionName", defaultCompetitionsOptions!.id);
            setValue("week", defaultWeeksOptions!.id);
            setValue("group", defaultGroupOptions!.id);
            setValue("phaseName", defaultPhasesOptions!.id);
            setSelectedPhaseId(defaultPhasesOptions!.id);
        }
    }

    let validationSchema = yup.object().shape({
        competitionName: yup.number().required(),
        week: yup.number().required(),
        group: yup.number().required(),
        phaseName: yup.number(),
        whenWeekInputDisabled: yup
            .number()
            .test("match", "Select a phase before using the week list", function () {
                return selectedPhaseId !== null;
            }),
    });

    const {control, errors, handleSubmit, setValue, getValues} = useForm({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        setWeekOptionsByPhase()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPhaseId]);

    const setWeekOptionsByPhase = () => {
        if (selectedPhaseId) {
            const selectedPhase = phaseOptions?.find((phase) => phase.id === selectedPhaseId);
            const newWeeksOptions = selectedPhase!.items?.map((week) => ({
                key: week?.id,
                value: week?.id,
                text: week?.name,
            }));
            // @ts-ignore
            const sortedWeeks = newWeeksOptions?.sort((a, b) => a.text - b.text)
            setWeeksOptions(sortedWeeks);
        }
    }
    const submitNewTournament = () => {
        const newTournament: ICreateTournamentPayload = {
            competitionId: getValues("competitionName"),
            weekId: getValues("week"),
            groupId: getValues("group"),
            phaseId: selectedPhaseId
        }
        const isTourExisting = checkIsExistTournament({
            tournamentsIds,
            editMode,
            editTournamentId,
            selectedWeek: newTournament.weekId,
            selectedGroup: newTournament.groupId,
            selectedCompetition: newTournament.competitionId
        });
        if (isTourExisting) {
            return toastr.error("", "Tournament is already exists")
        }
        dispatch(createDartsTournament(newTournament));
    };

    const updateTournament = () => {
        const updatedTournament: IEditTournamentPayload = {
            id: editTournamentId,
            competitionId: getValues("competitionName"),
            weekId: getValues("week"),
            groupId: getValues("group"),
            phaseId: selectedPhaseId
        };
        const isTourExisting = checkIsExistTournament({
            tournamentsIds,
            editMode,
            editTournamentId,
            selectedWeek: updatedTournament.weekId,
            selectedGroup: updatedTournament.groupId,
            selectedCompetition: updatedTournament.competitionId
        });
        if (isTourExisting) {
            return toastr.error("", "Tournament is already exists")
        }
        dispatch(editDartsTournament(updatedTournament));
    };

    return (
        <div className="form-overlay">
            <Form
                className={"tours-form"}
                onSubmit={
                    editMode
                        ? handleSubmit(updateTournament)
                        : handleSubmit(submitNewTournament)
                }
            >
                <Icon
                    color="red"
                    name="close"
                    size="large"
                    onClick={() => dispatch(hideTournamentForm())}
                    style={{float: "right", cursor: "pointer"}}
                />

                <Form.Field>
                    <Label>Competition:</Label>
                    <Controller
                        name="competitionName"
                        control={control}
                        render={(props) => (
                            <Select
                                value={getValues("competitionName")}
                                id="competitionName"
                                options={competitions}
                                placeholder="Select competition"
                                onChange={(e, {value}) => props.onChange(value)}
                            />
                        )}
                    />
                    {errors.competitionName && (
                        <Message negative>{errors.competitionName.message}</Message>
                    )}
                </Form.Field>
                <Form.Field>
                    <Label>Phase:</Label>
                    <Controller
                        name="phaseName"
                        control={control}
                        render={() => (
                            <Select
                                value={selectedPhaseId}
                                id="phaseName"
                                options={phases}
                                placeholder="Select phase"
                                onChange={(e, {value}) => {
                                    setSelectedPhaseId(value)
                                    const option = phases.find(phase => phase.value === value)
                                    if (option) {
                                        setTourTitle({...tourTitle, phase: option.text})
                                    }
                                }}
                            />
                        )}
                    />
                    {errors.phaseName && (
                        <Message negative>{errors.phaseName.message}</Message>
                    )}
                </Form.Field>
                <Form.Field>
                    <Label>Week:</Label>
                    <Controller
                        name="week"
                        control={control}
                        render={(props) => (
                            <Select
                                value={getValues("week")}
                                id="week"
                                options={weeksOptions}
                                placeholder="Select week"
                                disabled={!selectedPhaseId}
                                onChange={(e, {value}) => {
                                    props.onChange(value)
                                    const option = weeksOptions.find(week => week.value === value)
                                    if (option) {
                                        setTourTitle({...tourTitle, week: option.text})
                                    }
                                }}
                            />
                        )}
                    />
                    {errors.week && <Message negative>{errors.week.message}</Message>}
                    {errors.whenWeekInputDisabled && (
                        <Message negative>{errors.whenWeekInputDisabled.message}</Message>
                    )}
                </Form.Field>
                <div>

                </div>
                <Form.Field className={"temporary-field"}>
                    <div className={"temporary-message"}>
                        will be moved to game options
                    </div>
                    <Label>Group:</Label>
                    <Controller
                        name="group"
                        control={control}
                        render={(props) => (
                            <Select
                                value={getValues("group")}
                                id="group"
                                options={groups}
                                placeholder="Select group"
                                onChange={(e, {value}) => {
                                    props.onChange(value)
                                    const option = groups.find(group => group.value === value)
                                    if (option) {
                                        setTourTitle({...tourTitle, group: option.text})
                                    }
                                }}
                            />
                        )}
                    />
                    {errors.group && <Message negative>{errors.group.message}</Message>}
                </Form.Field>
                <Form.Field>
                    <Label>Title:</Label>
                    <Input
                        value={[tourTitle.phase, tourTitle.week, tourTitle.group].filter(Boolean).join(", ")}
                        readOnly={true}

                    />
                </Form.Field>
                <Button color="green" id="save_tournament" type="submit">
                    Submit
                </Button>
            </Form>
        </div>
    );
};

export default DartsTournamentsTableForm;
