import React, {useEffect, useState} from "react";
import {Button, Container, Dropdown, DropdownItemProps, Input, Progress} from "semantic-ui-react";
import Flatpickr from "react-flatpickr";
import {client} from "../../services/api.service";
import fileDownload from "js-file-download";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {
    getStatisticsInit, setDownloadedItems,
    setTotalItemsForDownloading
} from "../../store/pages/schedule/scheduleSlice";
import "./style.scss";
import styles from './index.module.scss'
import {defineUrlByStatsType} from "./helpers/helpers";
import {useDeepCompareEffect} from "../../hooks/useDeepCompareEffect";
import {PLAYERS_SCV_STATS} from "../../constants";

export enum StatsType {
    PLAYERS = "PLAYERS",
    GAMES = "GAMES"
}

export const Statistics = () => {
    const dispatch = useAppDispatch()
    const [startDate, setStartDate] = useState<string | Date>(new Date());
    const [endDate, setEndDate] = useState<string | Date>(new Date());
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [playersStatsBody, setPlayersStatsBody] = useState<{ playerId: any, takeLast: number }>
    ({playerId: 0, takeLast: 1});
    const {
        totalItemsForDownloading,
        downloadedItems,
        keyForDownloading,
        statsInitOptions
    } = useAppSelector((state) => state.schedule);

    useDeepCompareEffect(() => {
        dispatch(getStatisticsInit())
    }, [])


    useEffect(() => {
        if (totalItemsForDownloading === downloadedItems && totalItemsForDownloading !== 0) {
            getCSVStatistics()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalItemsForDownloading, downloadedItems])

    const getTotalGamesAndOpenSocket = async (from: string | Date, to: string | Date, type: StatsType) => {
        let body
        const url = defineUrlByStatsType(type)
        if (url === PLAYERS_SCV_STATS) {
            body = playersStatsBody
        } else {
            body = {from, to}
        }
        dispatch(setDownloadedItems(0))
        setIsLoading(true);
        dispatch(setTotalItemsForDownloading({count: 0, key: ""}))
        const {data} = await client.post(url, body);
        if (data.count === 0) {
            setIsLoading(false);
            return alert("There no items")

        }
        dispatch(setTotalItemsForDownloading(data))
        setIsLoading(false);
    };

    const getCSVStatistics = async () => {
        setIsLoading(true);
        try {
            const response = await client.get(`/api/darts-statistics/get-csv-file/${keyForDownloading}`, {
                responseType: 'blob',
            });

            const contentDisposition = response.headers['content-disposition'];
            let fileName = '';

            if (contentDisposition) {
                const fileNameMatch = contentDisposition?.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                if (fileNameMatch && fileNameMatch[1]) {
                    fileName = fileNameMatch[1];
                }
            }
            fileDownload(response.data, fileName);
        } catch (error) {
            console.error("Something went wrong", error);
        } finally {
            setIsLoading(false);
            setTimeout(() => {
                dispatch(setTotalItemsForDownloading({count: 0, key: ""}));
            }, 500);
        }
    };

    const dayPickerOptions = {
        dateFormat: "Y-m-d",
        locale: {
            firstDayOfWeek: 1,
        },
    };

    const playersOptions: DropdownItemProps[] = statsInitOptions?.map((player: { id: number; name: string }) => ({
        key: player.id,
        value: player.id,
        text: player.name,
    }));

    return (
        <div className={styles.statsPage}>
            <h1 className={styles.pageTitle}>Statistics</h1>
            <Container>
                <div className={styles.gameStatsBlock}>
                    <div className="statistics">
                        <div className={styles.statsTextInfo}>Ended darts games from</div>
                        <div className="statistics-inputs">
                            <div className="statistics__datepicker">
                                <Flatpickr
                                    className="statistics__flatpickr"
                                    value={startDate}
                                    options={dayPickerOptions}
                                    onChange={(date: Date, dayStr: string) => setStartDate(dayStr)}
                                />
                            </div>
                            <div>to</div>
                            <div className="statistics__datepicker">
                                <Flatpickr
                                    className="statistics__flatpickr"
                                    value={endDate}
                                    options={dayPickerOptions}
                                    onChange={(date: Date, dayStr: string) => setEndDate(dayStr)}
                                />
                            </div>
                        </div>
                        <div className="statistics__download-btn">
                            <Button
                                disabled={totalItemsForDownloading > 0}
                                loading={isLoading}
                                onClick={() => getTotalGamesAndOpenSocket(startDate, endDate, StatsType.GAMES)}
                                color="green"
                            >
                                Download
                            </Button>
                        </div>
                    </div>
                </div>

                <div className={styles.playerStatsBlock}>
                    <div className={styles.statsTextInfo}>Last</div>
                    <div>
                        <Input
                            value={playersStatsBody.takeLast}
                            onChange={(e) => setPlayersStatsBody({...playersStatsBody, takeLast: +e.target.value})}
                            className={styles.numberInput}
                            type={'number'}
                            min="1"
                            max="100"
                        />
                    </div>
                    <div className={styles.statsTextInfo}>Games(max 100) with players</div>
                    <div>
                        <Dropdown
                            value={playersStatsBody.playerId}
                            onChange={(e, {value}) =>
                                setPlayersStatsBody({...playersStatsBody, playerId: value})}
                            className={styles.playersSelect}
                            placeholder="Select players"
                            fluid
                            search
                            selection
                            options={playersOptions}
                        />
                    </div>
                    <div className="statistics__download-btn">
                        <Button
                            disabled={totalItemsForDownloading > 0}
                            loading={isLoading}
                            onClick={() => getTotalGamesAndOpenSocket(startDate, endDate, StatsType.PLAYERS)}
                            color="green"
                        >
                            Download
                        </Button>
                    </div>
                </div>
                {totalItemsForDownloading !== null && totalItemsForDownloading !== 0 && (
                    <Progress
                        color="green"
                        progress="value"
                        value={downloadedItems}
                        total={totalItemsForDownloading}/>
                )}
            </Container>
        </div>

    );
};

export default Statistics;
