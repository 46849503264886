import React from 'react';
import styles from "./index.module.scss"
import {Icon} from "semantic-ui-react";
import {useAppSelector} from "../../../../../hooks/hooks";
import moment from "moment/moment";


interface StackTraceProps {
    closeForm: () => void
}

const StackTrace: React.FC<StackTraceProps> = ({closeForm}) => {
    const {stackTrace} = useAppSelector(state => state.loggingExceptions)

    const getFormattedStringArray = (stackTrace: string) => {
        return stackTrace
            .split(' at ')
            .filter(line => line.trim() !== '')
            .map(line => `• ${line.trim()}`)
    }

    return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                <button onClick={closeForm} className={styles.closeBtn}>
                    <Icon color={"red"} name={"close"}/>
                </button>
                {stackTrace &&
                    <div className={styles.content}>
                        <div className={styles.traceInfo}>
                            <div className={styles.infoRow}>
                                <div className={styles.fieldName}>Id</div>
                                <div>:</div>
                                <div>{stackTrace.id}</div>
                            </div>
                            <div className={styles.infoRow}>
                                <div className={styles.fieldName}>Type</div>
                                <div>:</div>
                                <div>{stackTrace.type}</div>
                            </div>
                            <div className={styles.infoRow}>
                                <div className={styles.fieldName}>Date</div>
                                <div>:</div>
                                <div>{moment(stackTrace.date).format("YYYY:MM:DD HH:mm:ss")}</div>
                            </div>
                            <div className={styles.infoRow}>
                                <div className={styles.fieldName}>Message</div>
                                <div>:</div>
                                <div>{stackTrace.type}</div>
                            </div>
                        </div>
                        <div>{getFormattedStringArray(stackTrace.stackTrace).map(string => {
                            return (
                                <p>{string}</p>
                            )
                        })}</div>
                    </div>
                }
            </div>
        </div>
    );
};

export default StackTrace;
