import React from 'react';
import {useHistory} from "react-router-dom";
import "./index.scss"

const LoggingMenu = () => {
    const history = useHistory();
    return (
        <ul className={"loggingMenu"}>
            <li
                onClick={() => history.push('/logging-messages')}
                className={"menuItem"}>Messages
            </li>
            <li
                onClick={() => history.push('/logging-exceptions')}
                className={"menuItem"}>Exceptions
            </li>
            <li
                onClick={() => history.push('/logging-connections')}
                className={"menuItem"}>Connections
            </li>
        </ul>
    );
};

export default LoggingMenu;
