import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
    Button,
    Form,
    Input, Label
} from 'semantic-ui-react'
import '../style.scss';
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {
    createNewsItem,
    editModeHandler,
    editNewsItem,
    formHandler
} from "../../../../store/cms/modusNews/cmsModusNewsSlice";
import {formDataHandlers, updateFormData} from "../helpers/formDataHandlers";
import {inputFileHandler} from "../helpers/inputFileHandler";
import {getTag, getTagId} from "../helpers/getId";
import './style.scss';
import moment from "moment/moment";

export interface IFormData {
    titleValue: string;
    subtitleValue: string;
    tagValue: number;
    dateValue: string;
    fileValue: string;
    imgValue: string;
    imgName: string;
    youtubeLink: string;
    imageId: number;
    newsId: number;

}

const MssNewsForm = () => {
    const IMG_SRC = 'data:image/jpeg;base64,';
    const [formData, setFormData] = useState<IFormData>({
        tagValue: 1,
        youtubeLink:"",
        dateValue:moment().format("YYYY-MM-DD")
    } as IFormData);
    const [textEditorValue, setTextEditorValue] = useState('');
    const dispatch = useAppDispatch();
    const {editMode, newsItem,newsItemId} = useAppSelector((state) => state.cmsNews);
    const inputRef = useRef<any>(null);

    useEffect(() => {
        setDefaultFormValues()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newsItemId, newsItem])

    const setDefaultFormValues = () => {
        if (editMode && newsItem.id) {
            const {title, subtitle, newsDate, text, tag, image, videoUrl} = newsItem;
            setFormData( ({...formData,
                imgName: `${subtitle}`,
                titleValue: title,
                subtitleValue: subtitle,
                tagValue: tag,
                dateValue: newsDate?.substring(0, 10),
                imgValue: `${IMG_SRC}${image.image}`,
                newsId: image.newsId,
                imageId: image.id,
                youtubeLink: videoUrl || ""
            }));
            setTextEditorValue(text);

        }
    }
    const formVisibilityHandler = () => {
        dispatch(formHandler(false));
        dispatch(editModeHandler(false));
    }

    const createFormHandler = () => {
        const bodyFormData = formDataHandlers(formData, textEditorValue)
        if (textEditorValue.length === 0) {
            return alert('The "News body" field cannot be empty')
        }
        dispatch(createNewsItem(bodyFormData))
    }

    const updateFormHandler = () => {
        const editNewsItemPayload = updateFormData(formData, textEditorValue)

        const body = {
            payload: editNewsItemPayload,
            id: newsItem.id
        }
        dispatch(editNewsItem(body))
    }

    return (
        <div className={'mss-form'}>
            <div className="close-container" onClick={formVisibilityHandler}>
                <div className="leftright"></div>
                <div className="rightleft"></div>
            </div>
            <div>
                <Form onSubmit={editMode?updateFormHandler:createFormHandler} encType="multipart/form-data">
                    <div className="mss-label-control-wrapper">
                        <Label className='mss-label' content='Headline'/>
                        <Form.Field
                            className='mss-input'
                            placeholder='news item header'
                            control={Input}
                            type='text'
                            value={formData.titleValue}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({
                                ...formData,
                                titleValue: e.target.value
                            })}
                        />
                    </div>
                    <div className="mss-label-control-wrapper">
                        <Label className='mss-label' content='Subtitle*'/>
                        <Form.Field
                            required
                            className='mss-input'
                            placeholder='news series/week subtitle'
                            control={Input}
                            type='text'
                            value={formData.subtitleValue}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({
                                ...formData,
                                subtitleValue: e.target.value
                            })}

                        />
                    </div>
                    <div className="mss-label-control-wrapper">
                        <Label className='mss-label' content='Date'/>
                        <Form.Field
                            required
                            className='mss-input mss-date'
                            control={Input}
                            type='date'
                            value={formData.dateValue}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({
                                ...formData,
                                dateValue: e.target.value
                            })}
                        />

                    </div>
                    <div className="mss-label-control-wrapper">
                        <Label className='mss-label' content='Tag'/>
                        <select
                            className='mss-select'
                            id="tag"
                            value={getTag(formData.tagValue)}
                            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                setFormData({
                                    ...formData,
                                    tagValue: getTagId(e.target.value)
                                })
                            }
                            }

                        >
                            <option value="Blog">Blog</option>
                            <option value="Announcement">Announcement</option>
                            <option value="PressRelease">Press Release</option>
                        </select>
                    </div>
                    <div className="mss-label-control-wrapper">
                        <Label className='mss-label' content='Youtube'/>
                        <Form.Field
                            className='mss-input'
                            placeholder='Insert link'
                            control={Input}
                            type='text'
                            value={formData.youtubeLink}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({
                                ...formData,
                                youtubeLink: e.target.value
                            })}
                        />
                    </div>
                    <div className="mss-label-control-wrapper">
                        <Label className='mss-label' content='Image'/>
                        <input
                            placeholder={"File size - 1400 * 800"}
                            ref={inputRef}
                            id='img'
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => inputFileHandler({
                                e,
                                formData,
                                setFormData,
                                inputRef
                            })}/>
                    </div>
                    <div className={"file-placeholder"}>File size: 1400 * 800</div>
                    <div className="mss-image">
                        <img src={formData.imgValue} alt=""/>
                    </div>

                    <Label className='mss-label mss-label-textarea' content='Body*'/>
                    <div style={{height: '50%'}}>
                        <ReactQuill
                            className='mms-text-editor'
                            theme="snow"
                            value={textEditorValue}
                            onChange={setTextEditorValue}/>
                    </div>
                    <Form.Field
                        className='mss-submit'
                        color="green"
                        control={Button}
                        type='submit'
                        content='Create news Or Update'/>
                </Form>
            </div>
        </div>
    );
}

export default MssNewsForm;
