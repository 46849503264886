import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {API_CONNECTIONS} from "../../constants";
import {toastr} from "react-redux-toastr";
import {client} from "../../services/api.service";


interface IGetConnectionsResponse {
    totalItems: 0,
    pageNum: 0,
    pageSize: 0,
    message: string,
    isOK: true,
    sort: 0,
    sortField: string,
    search: string,
    items: ILoggingConnectionItem[],
    totalPages: number

}

interface ILoggingConnectionsState {
    totalItems: number,
    pageNum: number,
    pageSize: number,
    connections: ILoggingConnectionItem[]
    totalPages: number
}

export interface ILoggingConnectionItem {
    id: number,
    user: string,
    client: string,
    addressIP: string,
    message: string,
    status: string,
    timeStart: string
}

const initialState: ILoggingConnectionsState = {
    pageNum: 1,
    pageSize: 10,
    totalItems: 0,
    totalPages: 0,
    connections: []

}

export const getConnections = createAsyncThunk<IGetConnectionsResponse, string>(
    'logging/get-connections',
    async (query, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_CONNECTIONS}/?${query}`);
            return data
        } catch (e) {
            toastr.error('Logging get connections:', `${e.response.data}`);
            return rejectWithValue(e.response.data)
        }
    }
)


export const deleteConnection = createAsyncThunk<{ id: number | string }, string | number>(
    'logging/delete-connection',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`${API_CONNECTIONS}/${id}`);
            return data
        } catch (e) {
            toastr.error('Logging delete connections:', `${e.response.data}`);
            return rejectWithValue(e.response.data)
        }
    }
)


export const loggingConnectionsSlice = createSlice({
    name: 'loggingConnections',
    reducers: {
        setPageNum: (state, action) => {
            state.pageNum = action.payload
        },
        changePageSize:(state,action) => {
            state.pageSize = action.payload
        }
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getConnections.pending, (state: ILoggingConnectionsState) => {

            })
            .addCase(getConnections.fulfilled, (state: ILoggingConnectionsState, action) => {
                state.connections = action.payload.items
                state.totalPages = action.payload.totalPages
            })

            .addCase(deleteConnection.fulfilled, (state: ILoggingConnectionsState, action) => {
                state.connections = state.connections.filter(con => con.id !== action.meta.arg)
            })

    }
})
export const {setPageNum,changePageSize} = loggingConnectionsSlice.actions
export default loggingConnectionsSlice.reducer;



